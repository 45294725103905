import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "assets/arrowDown.svg";

interface DropdownProps {
  children?: React.ReactNode;
  className?: string;
  width?: string;
  innerText?: string;
  error?: boolean;
  disabled?: boolean;
}

const Dropdown = ({
  children,
  innerText,
  className,
  width,
  error,
  disabled,
}: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showOption, setShowOption] = useState(false);
  const [isAbove, setIsAbove] = useState(false);

  const checkDropdownDirection = () => {
    const rect = dropdownRef.current?.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    if (rect) {
      setIsAbove(rect.bottom + 290 > viewportHeight);
    }
  };

  useEffect(() => {
    if (showOption) {
      checkDropdownDirection();
    }
  }, [showOption]);

  useEffect(() => {
    const handleOutsideClose = (e: { target: any }) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target))
        setShowOption(false);
    };
    document.addEventListener("click", handleOutsideClose);

    return () => document.removeEventListener("click", handleOutsideClose);
  }, [dropdownRef]);

  return (
    <div
      ref={dropdownRef}
      onClick={() => {
        if (!disabled) {
          setShowOption((showOption) => !showOption);
        }
      }}
      className={`dropdown cursor-pointer box-border h-12 relative flex items-center justify-between px-4 py-3 border rounded-[10px] ${className} ${width}`}
    >
      <div
        className={`${
          innerText === "선택" || innerText === "구분"
            ? "text-Gray05"
            : "text-Gray09"
        }`}
      >
        {innerText}
      </div>
      <ArrowDown
        className={`transition-all ${showOption ? "rotate-180" : ""}`}
      />
      {showOption && (
        <div
          className={`first:px-4 first:py-3 absolute z-[101] list-none left-0 max-h-[290px] rounded-lg overflow-y-auto border border-Gray-40 bg-Gray-10 ${width} ${
            isAbove ? "bottom-full mb-2" : "top-full mt-2"
          }`}
        >
          <div className="p-2 bg-white">{children}</div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
