import CryptoJS from "crypto-js";

const encryptionKey = process.env.REACT_APP_CRYPTO_KEY!;

const base64Encode = (wordArray: CryptoJS.lib.WordArray) =>
  CryptoJS.enc.Base64.stringify(wordArray);
const base64Decode = (str: string) => CryptoJS.enc.Base64.parse(str);

const SECRET_KEY = CryptoJS.enc.Base64.parse(encryptionKey);
const IV_LENGTH = 16; // IV 길이

// IV 생성 함수
const generateRandomIV = () => CryptoJS.lib.WordArray.random(IV_LENGTH);

export const encryptData = (plainText: string) => {
  const iv = generateRandomIV();
  const encrypted = CryptoJS.AES.encrypt(plainText, SECRET_KEY, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const combined = iv.concat(encrypted.ciphertext);
  return base64Encode(combined);
};

export const decrypt = (cipherText: string) => {
    const decodedData = base64Decode(cipherText);

    // IV와 암호문 분리
    const iv = CryptoJS.lib.WordArray.create(decodedData.words.slice(0, IV_LENGTH / 4));
    const encrypted = CryptoJS.lib.WordArray.create(decodedData.words.slice(IV_LENGTH / 4));

    // CipherParams 객체 생성
    const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: encrypted
    });

    const decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        SECRET_KEY,
        {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }
    );

    return decrypted.toString(CryptoJS.enc.Utf8);
};