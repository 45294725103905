import React, { useCallback, useEffect, useState } from "react";
import {
  useAcceptCastingOfferMutation,
  useCastingOffers,
} from "api/CastingOffer/CastingOffer";
import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { ReactComponent as ArrowRight } from "assets/arrowRight.svg";
import BottomPopup from "components/BottomPopup";
import Button from "components/Button";
import { Toast } from "hooks/useToast";
import { useInView } from "react-intersection-observer";
import useScrollRestoration from "hooks/useScrollRestoration";
import ButtonBackground from "components/ButtonBackground";

const ArtistReceiveSuggest = () => {
  const { scrollRef } = useScrollRestoration();
  const [descInfo, setDescInfo] = useState(false);
  const [suggestInfo, setSuggestInfo] = useState<{
    name: string;
    castingCallName: string;
    desc: string;
    deadlineDate: string;
    castingCallId: null | number;
    castingOfferId: null | number;
    status: { code: string; label: string };
  }>({
    name: "",
    castingCallName: "",
    desc: "",
    deadlineDate: "",
    castingCallId: null,
    castingOfferId: null,
    status: {
      code: "",
      label: "",
    },
  });
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const { data, fetchNextPage, hasNextPage, refetch } = useCastingOffers({
    status: "",
  });

  const { mutate } = useAcceptCastingOfferMutation();

  const handleAcceptOffer = () => {
    mutate(
      { id: suggestInfo.castingOfferId! },
      {
        onSuccess: () => {
          setDescInfo(false);
          Toast.success("제안 받은 공고에 프로필을 보냈어요.");
          refetch();
        },
      }
    );
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  // OFFERED(제안), ACCEPTED(제안 수락), REJECTED(제안 거절), CLOSED(마감)
  return (
    <>
      <HeaderWithBackButton title="받은 제안" />
    <div className="px-5 pb-24">
      {descInfo && (
        <BottomPopup onClose={() => setDescInfo(false)}>
          <div className="BBody20 break-keep">
            {suggestInfo.name}님께 {suggestInfo.castingCallName}{" "}
            {suggestInfo.castingCallName ? "캐스팅" : ""} 제안을 받았어요!🙌
          </div>
          {suggestInfo.desc && (
            <div className="mt-4 mb-2 w-full h-[248px] overflow-scroll rounded-[10px] whitespace-pre-wrap bg-Gray01 RBody16 p-4">
              <div>{suggestInfo.desc}</div>
            </div>
          )}
          {suggestInfo.deadlineDate && (
            <div className="text-Blue04 BBody14 mb-4">
              {suggestInfo?.deadlineDate}까지 수락하지 않으면 자동으로 취소돼요.
            </div>
          )}
          <div className={`RBody14 text-Gray05 ${!suggestInfo.desc && "mt-4"}`}>
            스탭 회원의 캐스팅 공고와 제안은 캐스팅보트가 책임지지 않으니
            승인되지 않은 스탭의 허위 공고 또는 제안을 조심하세요.
          </div>
          <ButtonBackground>
            <Button
              onClick={() => {
                setDescInfo(false);
                setSuggestInfo({
                  name: "",
                  castingCallName: "",
                  desc: "",
                  deadlineDate: "",
                  castingCallId: null,
                  castingOfferId: null,
                  status: { code: "", label: "" },
                });
              }}
              className={`bg-Gray03 flex-1 text-Gray05 border-none`}
              text={suggestInfo.castingCallName ? "닫기" : "확인"}
            ></Button>
            {suggestInfo?.status.code !== "ACCEPTED" &&
            suggestInfo.castingCallId !== null ? (
              <Button
                onClick={() => {
                  handleAcceptOffer();
                }}
                className="bg-Blue04 flex-1 text-Gray00 border-none"
                text={"수락하기"}
              ></Button>
            ) : (
              ""
            )}
          </ButtonBackground>
        </BottomPopup>
      )}
      {data?.pages[0].data.content.length === 0 ? (
        <div className="mt-36 items-center text-center">
          <div className="text-Gray05 RBody14">받은 제안이 없어요.</div>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {data?.pages.map((item, i) => {
            return item.data.content.map((offerItem: any) => {
              const createdForm = offerItem.createdAt.split("T");
              const date = createdForm[0].split("-");
              const createdAt = `${date[1]}월 ${date[2]}일`;
              return (
                <div
                  key={offerItem.castingOfferId}
                  onClick={() => {
                    setDescInfo(true);
                    setSuggestInfo({
                      name: offerItem.staffName,
                      castingCallName: offerItem.castingCallTitle,
                      desc: offerItem.description,
                      deadlineDate: offerItem.deadlineDate,
                      castingCallId: offerItem.castingCallId,
                      castingOfferId: offerItem.castingOfferId,
                      status: offerItem.status,
                    });
                  }}
                  className="flex flex-col gap-2 cursor-pointer"
                >
                  <div className="RBody14 text-Gray05">{createdAt}</div>
                  <div className="flex items-center justify-between">
                    <div className="text-Gray09 MBody16 ">
                      {offerItem.staffName}님께 {offerItem.castingCallTitle}{" "}
                      캐스팅 제안을 받았어요.
                    </div>
                    <ArrowRight />
                  </div>
                  <div className="BBody14 text-Blue04">
                    {offerItem.staffName}
                  </div>
                </div>
              );
            });
          })}
          <div ref={ref} />
        </div>
      )}
    </div>
    </>
  );
};

export default ArtistReceiveSuggest;
