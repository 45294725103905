import React, { useState } from "react";
import Skeleton from "./Skeleton";

interface ArtistCardType {
  title: string;
  subTitle?: string;
  description?: string;
  onClick?: () => void;
  src?: string;
}

const ArtistCard = ({
  title,
  subTitle,
  description,
  src,
  onClick,
}: ArtistCardType) => {
  const [isLoading, setIsLoading] = useState(true);
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  return (
    <div className="relative w-[139px]">
      {isLoading && <Skeleton type="ARTIST_CARD" />}
      {MEDIA_URL && (
        <img
          onLoad={(e) => {
            setIsLoading(false);
          }}
          src={`${MEDIA_URL}${src}`}
          alt="Thumnail"
          onClick={onClick}
          className="object-cover w-[139px] h-[173.75px] cursor-pointer rounded-[10px]"
        />
      )}
      <div className="flex items-center gap-0.5 mt-2">
        <div className="BBody16 text-Gray09 max-w-[81px] text-ellipsis whitespace-nowrap overflow-hidden">
          {title}
        </div>
        <div className="MBody14 text-Gray05">{subTitle}</div>
      </div>
      <div className="MBody16 max-w-[145px] text-ellipsis overflow-hidden whitespace-nowrap text-Gray05">
        {description}
      </div>
    </div>
  );
};

export default ArtistCard;
