import React from "react";

import { ReactComponent as Search } from "../assets/icon_search.svg";

interface SearchBoxType {
  placeholder: string;
  className?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDropDownChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  children?: React.ReactNode;
}

const SearchBox = ({
  placeholder,
  className,
  value,
  onChange,
  onDropDownChange,
  children,
}: SearchBoxType) => {
  return (
    <div className="relative w-full MBody14">
      <input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`w-full bg-Gray01 top-0.5 py-2 pr-[34px] rounded-full pl-[77px] ${className}`}
      />
      <select
        onChange={onDropDownChange}
        className="w-[67px] bg-Gray00 rounded-full h-[34px] absolute left-0.5 top-0.5 MCaption14"
      >
        {children}
      </select>
      <Search className="absolute top-2.5 right-4" />
    </div>
  );
};

export default SearchBox;
