import React, { useState } from "react";

import { ReactComponent as Back } from "assets/headerBack.svg";
import { useNavigate } from "react-router-dom";
import Input from "components/Input";
import { LineButton } from "components/LineButton";
import { useCardUpdate } from "api/Payment/Payment";

const MembershipPaymentMethod = () => {
  const navigate = useNavigate();
  const [cardInfo, setCardInfo] = useState<{
    cardNumber: string;
    cardExpireMM: string;
    cardExpireYY: string;
    cardHolderName: string;
    cardPasswd: string;
  }>({
    cardNumber: "",
    cardExpireMM: "",
    cardExpireYY: "",
    cardHolderName: "",
    cardPasswd: "",
  });

  const { mutate, isLoading } = useCardUpdate();

  return (
    <div className="w-full pb-[103px] min-w-[280px] max-w-[400px] min-h-screen px-5 bg-Gray00 mx-auto">
      <div className="mx-auto px-5 py-2 fixed bg-Gray00 left-2/4 top-0 z-50 -translate-x-2/4 max-w-[400px] w-full BBody20 text-Gray09 ">
        <div className="flex justify-between items-center">
          <Back
            className="cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className="absolute left-2/4 text-Gray09 BBody20 -translate-x-2/4 max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            결제 수단 변경
          </div>
        </div>
      </div>

      <div className="w-full pt-[74px]">
        <div className="flex flex-col BBody16 mb-4 w-full gap-4">
          <div>
            <div>카드번호</div>
            <Input
              inputMode="numeric"
              className="mt-2"
              value={cardInfo?.cardNumber}
              onChange={(e) => {
                const { value } = e.target;
                const numericValue = value.replace(/\D/g, "");

                if (numericValue.length > 16) return;

                let formattedValue = numericValue;
                if (numericValue.length > 4) {
                  formattedValue =
                    numericValue.slice(0, 4) + "-" + numericValue.slice(4);
                }
                if (numericValue.length > 8) {
                  formattedValue =
                    formattedValue.slice(0, 9) + "-" + formattedValue.slice(9);
                }
                if (numericValue.length > 12) {
                  formattedValue =
                    formattedValue.slice(0, 14) +
                    "-" +
                    formattedValue.slice(14);
                }

                setCardInfo({
                  ...cardInfo,
                  cardNumber: formattedValue,
                });
              }}
              onKeyDown={(e) => {
                if (["e", "E", "+", "."].includes(e.key)) {
                  e.preventDefault();
                }
              }}
              placeholder="0000 0000 0000 0000"
              type={"text"}
            ></Input>
          </div>
          <div>
            <div>유효기간</div>
            <div className="flex gap-2 mt-2 items-center">
              <Input
                inputMode="numeric"
                value={cardInfo?.cardExpireMM}
                onChange={(e) => {
                  const value = e.target.value
                    .replace(/[^0-9]/g, "")
                    .slice(0, 2);
                  if (
                    value.length === 2 &&
                    (Number(value) < 1 || Number(value) > 12)
                  ) {
                    return;
                  }
                  setCardInfo({
                    ...cardInfo,
                    cardExpireMM: value,
                  });
                }}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-", "."].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                placeholder="MM"
                type={"text"}
              />
              <Input
                inputMode="numeric"
                value={cardInfo?.cardExpireYY}
                onChange={(e) => {
                  // const currentYear = new Date().getFullYear() % 100; // 현재 연도의 두 자리 표현 (예: 2024 -> 24)
                  const value = e.target.value
                    .replace(/[^0-9]/g, "")
                    .slice(0, 2);
                  //   .replace(/[^0-9]/g, "")
                  //   .slice(0, 2);
                  // if (value.length === 2 && Number(value) < currentYear) {
                  //   return; // 만료된 연도는 무시
                  // }
                  setCardInfo({
                    ...cardInfo,
                    cardExpireYY: value,
                  });
                }}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-", "."].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                placeholder="YY"
                type={"number"}
              />
            </div>
          </div>
          <div>
            <div>이름</div>
            <Input
              value={cardInfo?.cardHolderName}
              onChange={(e) => {
                const value = e.target.value;
                setCardInfo({
                  ...cardInfo,
                  cardHolderName: value,
                });
              }}
              className="mt-2"
              placeholder="이름"
              type={"text"}
            />
          </div>
          <div>
            <div>카드 비밀번호</div>
            <div className="flex gap-2 mt-2 items-center">
              <Input
                inputMode="numeric"
                value={cardInfo?.cardPasswd}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-", "."].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setCardInfo({
                    ...cardInfo,
                    cardPasswd: e.target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 2),
                  });
                }}
                className="flex-1"
                placeholder="00"
                type={"password"}
              />
              <div className="flex min-w-44 items-center gap-2">
                <div className="w-2 h-2 rounded-full bg-Gray09"></div>
                <div className="w-2 h-2 rounded-full bg-Gray09"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[400px] bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 pb-[35px] px-5">
        <LineButton
          text="변경하기"
          onClick={() => {
            mutate(
              {
                ...cardInfo,
                cardNumber: cardInfo.cardNumber.replaceAll("-", ""),
              },
              {
                onSuccess: () => {
                  navigate("/membership/my", { replace: true });
                },
              }
            );
          }}
          className="w-full"
          variant="normal"
          size="L"
        ></LineButton>
      </div>
    </div>
  );
};

export default MembershipPaymentMethod;
