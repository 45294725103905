import React from "react";

const Terms = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
        서비스 이용약관
      </h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b pb-2 mb-4">
          제1장: 총칙
        </h2>

        <article className="mb-6">
          <h3 className="text-lg font-bold text-gray-800 mb-2">제1조 (목적)</h3>
          <p className="text-gray-600 leading-relaxed">
            본 이용약관은 주식회사 [커넥션스튜디오] (이하 "회사")가 운영하는
            캐스팅 플랫폼(CastingVote) 서비스(이하 "서비스")의 이용 조건과 절차,
            회원과 회사 간의 권리, 의무 및 책임 사항 등을 규정함을 목적으로
            합니다.
          </p>
        </article>

        <article className="mb-6">
          <h3 className="text-lg font-bold text-gray-800 mb-2">
            제2조 (용어의 정의)
          </h3>
          <p className="text-gray-600 leading-relaxed">
            본 이용약관에서 사용되는 용어의 정의는 다음과 같습니다:
          </p>
          <ul className="list-disc list-inside pl-4 text-gray-600">
            <li>
              “사이트”라 함은 회사가 서비스를 제공하기 위해 설정한 가상의
              영업장을 의미하며, castingvote.io를 포함합니다.
            </li>
            <li>
              “서비스”란 회사가 제공하는 온라인 상의 캐스팅 정보 제공 및 관리
              서비스를 의미합니다.
            </li>
            <li>
              “이용자”란 서비스를 이용하는 모든 회원과 비회원을 포함한 모든 자를
              의미합니다.
            </li>
            <li>
              “회원”이란 서비스에 개인정보를 제공하여 회원가입을 한 자로서,
              서비스를 이용할 수 있는 자를 의미합니다.
            </li>
          </ul>
        </article>

        <article className="mb-6">
          <h3 className="text-lg font-bold text-gray-800 mb-2">
            제3조 (약관의 효력 및 변경)
          </h3>
          <p className="text-gray-600 leading-relaxed">
            회사는 이 약관의 내용과 상호, 영업소 소재지, 대표자 성명,
            사업자등록번호, 연락처 등을 초기 화면에 게시하여 회원이 이를 알 수
            있도록 합니다...
          </p>
        </article>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b pb-2 mb-4">
          제2장: 서비스 이용
        </h2>

        <article className="mb-6">
          <h3 className="text-lg font-bold text-gray-800 mb-2">
            제4조 (서비스의 제공)
          </h3>
          <p className="text-gray-600 leading-relaxed">
            회사는 이용자에게 캐스팅 정보 제공 및 신청, 캐스팅 업무 관리, 연락처
            제공 등의 서비스를 제공합니다.
          </p>
        </article>

        <article className="mb-6">
          <h3 className="text-lg font-bold text-gray-800 mb-2">
            제5조 (이용계약의 성립)
          </h3>
          <p className="text-gray-600 leading-relaxed">
            이용자가 회사가 제공하는 가입 양식에 필요한 정보를 기입하고 본
            약관에 동의하여 회원가입을 신청하면, 이용계약이 성립됩니다.
          </p>
        </article>

        <article className="mb-6">
          <h3 className="text-lg font-bold text-gray-800 mb-2">
            제6조 (서비스 이용요금)
          </h3>
          <p className="text-gray-600 leading-relaxed">
            회원가입과 프로필 작성은 무료입니다. 다만, 법인회원 및 웹사이트 방문
            기업에게는 유료 서비스가 제공될 수 있습니다.
          </p>
        </article>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b pb-2 mb-4">
          제3장: 회사 및 회원의 의무
        </h2>

        <article className="mb-6">
          <h3 className="text-lg font-bold text-gray-800 mb-2">
            제9조 (회사의 의무)
          </h3>
          <p className="text-gray-600 leading-relaxed">
            회사는 회원이 안정적으로 서비스를 이용할 수 있도록 최선을 다해
            노력하며, 서비스 제공과 관련된 시스템 유지보수, 오류 복구 등을
            신속하게 처리합니다.
          </p>
        </article>

        <article className="mb-6">
          <h3 className="text-lg font-bold text-gray-800 mb-2">
            제10조 (회원의 의무)
          </h3>
          <p className="text-gray-600 leading-relaxed">
            회원은 서비스를 이용함에 있어 관련 법령과 약관의 규정을 준수하며,
            회사의 서비스 운영을 방해하는 행위를 하지 않습니다.
          </p>
        </article>
      </section>

      <footer className="text-gray-500 text-sm mt-8">
        <p>본 이용약관은 회사의 운영 정책에 따라 변경될 수 있습니다.</p>
      </footer>
    </div>
  );
};

export default Terms;
