import { AxiosError, AxiosResponse } from "axios";
import { Toast } from "hooks/useToast";
import { UseQueryResult, useQuery } from "react-query";
import { ERROR } from "type/common";
import { StaffResponse } from "type/member";
import requestToCastingVote from "utils/requestToCastingVote";

// 스태프 목록 조회
const getStaffList = (name: string = "", page: number = 0, size: number = 10) => {
    return requestToCastingVote({
      method: "GET",
      url: "/members/staff",
      params: {
        name,
        page,
        size,
      },
    });
  };
  export const useStaffProfileList = (
    name: string = "",
    page: number = 0,
    size: number = 200
  ): UseQueryResult<AxiosResponse<StaffResponse>, AxiosError<ERROR>> =>
    useQuery(["staffs", name, page, size], () => getStaffList(name, page, size),{
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      }
    });