import React from "react";

import notFound from "assets/404.png";
import { FillButton } from "components/FillButton";
import { useNavigate } from "react-router";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col max-w-[289px] mt-[200px] text-center items-center justify-center mx-auto">
      <img className=" w-[172px] h-[102px]" src={notFound} alt="404" />
      <div className="flex flex-col gap-2 my-4">
        <div className="BBody20">존재하지 않는 URL이에요</div>
        <div className="MCaption14 text-Gray05">
          찾으시려는 페이지의 주소가 잘못 입력되었거나, 주소 변경 또는 삭제로
          인해 이 페이지를 열 수 없어요. 입력하신 주소를 다시 확인해 주세요.
        </div>
      </div>
      <FillButton
        onClick={() => {
          navigate("/", { replace: true });
        }}
        text="홈으로 가기"
        variant="Primary"
        size="L"
      ></FillButton>
    </div>
  );
};

export default NotFound;
