import React from "react";

interface ButtonType {
  text: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  isLoading?: boolean;
}

const Button = ({
  text,
  className,
  onClick,
  onKeyDown,
  disabled,
  isLoading,
}: ButtonType) => {
  return (
    <button
      onKeyDown={onKeyDown}
      disabled={disabled}
      onClick={onClick}
      className={`disabled:cursor-default py-3 text-center rounded-lg disabled:border-none disabled:bg-Blue04/20 cursor-pointer ${className}`}
    >
      {isLoading ? (
        <div className="w-4 h-4 mx-auto border-2 border-t-transparent border-Gray00 rounded-full animate-spin"></div>
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
