import React from "react";

// import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";

interface CastingRecruitCardType {
  onClick?: () => void;
  type?: string;
  title?: string;
  gender?: string;
  age?: string;
}

const CastingRecruitCard = ({
  onClick,
  type,
  title,
  gender,
  age,
}: CastingRecruitCardType) => {
  return (
    <div
      onClick={onClick}
      className="w-full cursor-pointer p-4 border border-[#D9D9D9] rounded-[10px]"
    >
      <div className="flex items-center text-Gray05 MBody14 justify-between">
        <div>{type && type}</div>
        {/* <Bookmark /> */}
      </div>
      <div className="max-w-[326px] my-2 BBody18 w-full">{title}</div>
      <div className="flex items-center MBody14 text-Gray09 gap-2">
        <div>{gender}</div>
        <div>|</div>
        <div>{age}</div>
      </div>
    </div>
  );
};

export default CastingRecruitCard;
