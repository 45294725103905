import React, { ButtonHTMLAttributes, FC } from "react";
import { cva, VariantProps } from "class-variance-authority";

interface FillButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof FillButtonVariants> {
  className?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  text?: string;
}

export const FillButtonVariants = cva(
  `
  hover:bg-opacity-80 disabled:opacity-20 text-center rounded-md
  `,
  {
    variants: {
      variant: {
        Membership: "bg-Blue04 text-Gray00",
        Primary: "bg-Gray09 text-Gray00",
        Secondary: "bg-Gray01 text-Gray09",
      },
      size: {
        S: "MCaption12 h-7 px-2.5",
        M: "MCaption14 h-[38px] px-4",
        L: "MBody16 h-12 px-4",
        B: "MBody16 h-[52px] w-full",
      },
    },
  }
);

export const FillButton: FC<FillButtonProps> = ({
  variant,
  size,
  children,
  className,
  isLoading,
  text,
  ...props
}) => {
  return (
    <button
      disabled={isLoading}
      className={FillButtonVariants({ variant, size, className })}
      {...props}
    >
      {isLoading ? (
        <div className="w-4 h-4 mx-auto border-2 border-t-transparent border-Gray00 rounded-full animate-spin"></div>
      ) : (
        text
      )}
    </button>
  );
};
