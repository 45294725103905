import { AxiosError, AxiosResponse } from "axios";
import { Toast } from "hooks/useToast";
import { useQuery, UseQueryResult } from "react-query";
import { ApiResponse, CastingCallsResponse, ERROR, MainCasting, MainCommon } from "type/common";
import requestToCastingVote from "utils/requestToCastingVote";

// 진행중인 캐스팅
const getMainCastingList = ({ status, page, size }: MainCasting) => {
  return requestToCastingVote({
    method: "GET",
    url: "home/castingcalls",
    params: {
      status,
      page,
      size,
    },
  });
};
export const useMainCastingList = (
  params: MainCasting
): UseQueryResult<AxiosResponse<CastingCallsResponse>, AxiosError<ERROR>> =>
  useQuery(["useMainCastingList", params], () => getMainCastingList(params),
{
  onError: (error: AxiosError<ERROR>) => {
    Toast.error(error.response?.data.message);
  }
});

// 지금 뜨고있는 프로필
const MainHot = ({ page, size }: MainCommon) => {
  return requestToCastingVote({
    method: "GET",
    url: "home/profiles/hot",
    params: {
      page,
      size,
    },
  });
};
export const useMainHotList = (
  params: MainCommon
): UseQueryResult<AxiosResponse<ApiResponse>, AxiosError<ERROR>> =>
  useQuery(["useMainHotList", params], () => MainHot(params),
{
  onError: (error: AxiosError<ERROR>) => {
    Toast.error(error.response?.data.message);
  },
});

// 새로 올라온 프로필
const getMainNewList = ({ page, size }: MainCommon) => {
  return requestToCastingVote({
    method: "GET",
    url: "home/profiles/new",
    params: {
      page,
      size,
    },
  });
};
export const useMainNewList = (
  params: MainCommon
): UseQueryResult<AxiosResponse<ApiResponse>, AxiosError<ERROR>> =>
  useQuery(["useMainNewList", params], () => getMainNewList(params),
{
  onError: (error: AxiosError<ERROR>) => {
    Toast.error(error.response?.data.message);
  },
});
