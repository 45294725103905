import { useChangePassword } from "api/auth";
import ButtonBackground from "components/ButtonBackground";
import { FillButton } from "components/FillButton";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import Input from "components/Input";
import TitleWrap from "components/TitleWrap";
import { Toast } from "hooks/useToast";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { encryptData } from "utils/crypto";

const MemberFindPassword = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordFindData, setPasswordFindData] = useState({
    userId: "",
    password: "",
    phoneNumber: state.phoneNumber,
    certNum: state.certNum,
  });
  const { mutate: changePassword, isLoading } = useChangePassword();

  const handleChangePassword = () => {
    changePassword(
      {
        userId: passwordFindData?.userId,
        password: encryptData(passwordFindData?.password),
        phoneNumber: passwordFindData?.phoneNumber,
        certNum: passwordFindData?.certNum,
      },
      {
        onSuccess: () => {
          Toast.success("비밀번호가 변경되었어요");
          navigate("/login", { replace: true });
        },
      }
    );
  };

  const isButtonDisabled = !(
    passwordFindData.userId &&
    passwordFindData.password &&
    passwordFindData.phoneNumber &&
    passwordFindData.certNum &&
    passwordConfirm
  );

  return (
    <div>
      <HeaderWithBackButton title={"비밀번호 찾기"} />
      <div className="px-5 pt-5 flex flex-col gap-10">
        <TitleWrap title={"아이디"}>
          <Input
            type={"text"}
            value={passwordFindData.userId}
            onChange={(e) => {
              setPasswordFindData({
                ...passwordFindData,
                userId: e.target.value,
              });
            }}
            placeholder="아이디"
          ></Input>
        </TitleWrap>
        <TitleWrap title={"새로운 비밀번호"}>
          <Input
            type={"password"}
            value={passwordFindData.password}
            onChange={(e) => {
              const passwordValue = e.target.value;
              if (passwordValue.length <= 50) {
                setPasswordFindData({
                  ...passwordFindData,
                  password: e.target.value.trim(),
                });
              }
            }}
            placeholder="비밀번호"
          ></Input>
        </TitleWrap>
        <TitleWrap title={"비밀번호 확인"}>
          <Input
            value={passwordConfirm}
            onChange={(e) => {
              const passwordValue = e.target.value;
              if (passwordValue.length <= 50) {
                setPasswordConfirm(e.target.value.trim());
              }
            }}
            type={"password"}
            placeholder="비밀번호 확인"
          ></Input>
        </TitleWrap>
      </div>
      <ButtonBackground>
        <FillButton
          disabled={
            isLoading ||
            isButtonDisabled ||
            passwordFindData.password !== passwordConfirm
          }
          onClick={() => {
            handleChangePassword();
          }}
          variant="Membership"
          text="계속"
          size="B"
        />
      </ButtonBackground>
    </div>
  );
};

export default MemberFindPassword;
