import React, { useEffect, useState } from "react";

import MainWrap from "components/MainWrap";
import ArtistCard from "components/ArtistCard";
import { useLocation, useNavigate } from "react-router-dom";

import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import FAQ from "assets/Main.png";
import Banner from "assets/Banner.png";

import { Autoplay } from "swiper/modules";
import ContentWrap from "components/ContentWrap";
import CastingCard from "components/CastingCard";
import { useMainCastingList, useMainHotList, useMainNewList } from "api/Main";
import { MainCasting, MainCommon } from "type/common";
import { useRecoilState } from "recoil";
import { innderHeightValue } from "store/auth/atom";
import { FillButton } from "components/FillButton";
import Header from "components/Header";

const Main = () => {
  const navigate = useNavigate();
  const [innderHeight, setInnderHeight] =
    useRecoilState<number>(innderHeightValue);

  const [mainCastingType, setMainCastingType] = useState<MainCasting>({
    status: "",
    page: 0,
    size: 20,
  });
  const [mainHotType, setMainHotType] = useState<MainCommon>({
    page: 0,
    size: 20,
  });
  const [mainNewType, setMainNewType] = useState<MainCommon>({
    page: 0,
    size: 20,
  });

  const { data: castingList } = useMainCastingList(mainCastingType);
  const { data: hotList } = useMainHotList(mainHotType);
  const { data: newList } = useMainNewList(mainNewType);

  return (
    <>
      <div className="rolling-container MCaption12">
        <div className="rolling-text">
          <div>
            회원가입 후 프로필을 등록해주세요, 베타 오픈 기간동안{" "}
            <span className="text-[#7EC9FF] font-bold">오전, 오후 8~10시</span> 사이 서비스가
            원활하지 않을 수 있어요.
          </div>
          <div>
            회원가입 후 프로필을 등록해주세요, 베타 오픈 기간동안{" "}
            <span className="text-[#7EC9FF] font-bold">오전, 오후 8~10시</span> 사이 서비스가
            원활하지 않을 수 있어요.
          </div>
        </div>
      </div>
      <Header /> 
      <div className="pb-10">
        <div className="px-5">
          <Swiper
            className="px-5"
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[Autoplay]}
            loop={true}
            spaceBetween={8}
            slidesPerView={"auto"}
          >
            <SwiperSlide className="w-fit">
              <img
                onClick={() => {
                  window.open(
                    "https://daffy-periwinkle-38f.notion.site/FAQ-1344e358e81b80f9bbd3d7c0a76f3398",
                    "_blank",
                    "noopener, noreferrer"
                  );
                }}
                className="mt-2 cursor-pointer rounded-[10px]"
                src={FAQ}
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="w-fit">
              <img
                onClick={() => {
                  window.open(
                    "https://daffy-periwinkle-38f.notion.site/1344e358e81b8068b747ee7bc9467433",
                    "_blank",
                    "noopener, noreferrer"
                  );
                }}
                className="mt-2 cursor-pointer rounded-[10px]"
                src={Banner}
                alt=""
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <MainWrap
          className="relative"
          onClick={() => {
            navigate("/casting");
          }}
          more={true}
          title="진행중인 캐스팅"
        >
          <Swiper
            slidesPerView={
              castingList?.data.data.content.length! < 4 ? 2 : "auto"
            }
            spaceBetween={16}
          >
            {castingList?.data.data.content.map((item, i) => {
              if (item.productionThumbnailUrl !== null)
                return (
                  <SwiperSlide
                    className={
                      castingList?.data.data.content.length! < 2
                        ? "w-full"
                        : "!w-fit"
                    }
                    key={item.castingCallId}
                  >
                    <div className="w-full">
                      <CastingCard
                        onClick={() => {
                          navigate(`/casting/${item.castingCallId}`);
                        }}
                        title={item?.title}
                        src={item?.productionThumbnailUrl}
                        type={item?.productionFormat?.label}
                      />
                    </div>
                  </SwiperSlide>
                );
            })}
          </Swiper>
        </MainWrap>
        <MainWrap
          className="relative"
          more={false}
          onClick={() => {
            navigate("/artist/pick");
          }}
          title="캐스팅보트 P!ck"
        >
          <Swiper
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[Autoplay]}
            slidesPerView={hotList?.data.data.content.length! < 4 ? 2 : "auto"}
            spaceBetween={16}
          >
            {hotList?.data.data.content.map((item, i) => {
              return (
                <SwiperSlide
                  className={
                    hotList?.data.data.content.length! < 2 ? "w-full" : "!w-fit"
                  }
                  key={i}
                >
                  <div className="w-full">
                    <ArtistCard
                      src={item.thumbnailUrl}
                      // onClick={() => {
                      //   navigate(`/artist/${item.deliveryProfileId}`);
                      // }}
                      title={item.profileName}
                      subTitle={item.job.label}
                      description={
                        item.productionTitle
                          ? item.productionTitle
                          : "미공개작품"
                      }
                    />
                  </div>
                </SwiperSlide>
              );
            })}
            {/* <SwiperSlide>
            <div className="w-[139px] h-[173.75px] bg-black rounded-[10px] p-2">
              <div className="text-Gray00">
              
              </div>
            </div>
          </SwiperSlide> */}
          </Swiper>
        </MainWrap>
        <MainWrap
          className="relative"
          title="새로 올라온 프로필"
          onClick={() => {
            navigate("/artist");
          }}
          more
        >
          <Swiper
            // autoplay={{ delay: 2000, disableOnInteraction: false }}
            // modules={[Autoplay]}
            slidesPerView={newList?.data.data.content.length! < 4 ? 2 : "auto"}
            spaceBetween={16}
          >
            {newList?.data.data.content.map((item, i) => {
              const date = item.birthDate?.split("-")[0];
              return (
                <SwiperSlide
                  className={
                    newList?.data.data.content.length! < 2 ? "w-full" : "!w-fit"
                  }
                  key={item.profileId}
                >
                  <div className="w-full">
                    <ArtistCard
                      src={item.thumbnailUrl}
                      onClick={() => {
                        navigate(`/artist/${item.profileId}`);
                      }}
                      title={item.profileName}
                      subTitle={`${date}년생`}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </MainWrap>
        {/* <MainWrap more={false} title="캐스팅보트 핫클립">
        <YouTube
          className={"w-full"}
          opts={opt}
          iframeClassName="youtubeRounded"
          videoId="QLXEu3SVbh8"
        />
      </MainWrap> */}
      </div>
    </>
  );
};

export default Main;
