import React, { useEffect, useState } from "react";
import ContentWrap from "components/ContentWrap";

import { ReactComponent as Share } from "../assets/share.svg";
import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";

import Insta from "../assets/Instagram_black.png";
import Youtue from "../assets/YouTube_black.png";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { info } from "store/auth/atom";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import YouTube from "react-youtube";
import { Autoplay } from "swiper/modules";
import { ReactComponent as SendFeedback } from "assets/sendFeedback.svg";
import { ReactComponent as Feedback } from "assets/icon_checkbox.svg";
import { ReactComponent as BlankStar } from "assets/blankStar.svg";
import { ReactComponent as Star } from "assets/star.svg";
import ImageModal from "components/ImageModal";
import {
  useReceivedProfileDetails,
  useSubmitFeedback,
} from "api/DeliveryProfile/DeliveryProfile";
import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import BottomButtonPopup from "components/BottomButtonPopup";
import BottomPopup from "components/BottomPopup";
import Textarea from "components/Textarea";
import Button from "components/Button";
import { Toast } from "hooks/useToast";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useShareLink } from "api/Share/Share";
import Modal from "components/Modal";
import { handleCopyClipBoard } from "utils/onCopyClipBoard";
import ScrollToTop from "components/ScrollToTop";
import ButtonBackground from "components/ButtonBackground";

const ArtistFeedback = () => {
  const { artistId } = useParams();
  const [feedbackScore, setFeedbackScore] = useState(0);
  const [review, setReview] = useState("");
  const [copyModal, setCopyModal] = useState(false);
  const [profileId, setProfileId] = useState<null | number>(null);
  const [imageDetail, setImageDetail] = useState(false);
  const [sendFeedback, setSendFeedback] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [userData, setUserData] = useRecoilState<{
    id: string;
    username: string;
    role: string;
  }>(info);
  const [swiper, setSwiper] = useState<SwiperClass>();

  const { data, isLoading, isError, refetch } = useReceivedProfileDetails(
    Number(artistId)
  );

  const ArtistData = data?.data?.data;

  const opt = {
    height: "203",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  const calculateAge = () => {
    const currentYear = new Date().getFullYear();
    const calculatedAge =
      currentYear - Number(ArtistData?.birthDate.split("-")[0]);
    return calculatedAge;
  };

  const handleOpenNewTab = (url: string) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  const groupedByYear = ArtistData?.filmographies?.reduce((acc: any, item) => {
    const year = item.releaseYear;
    if (!acc[year]) {
      acc[year] = [];
    }

    acc[year].push(item);

    acc[year].sort((a: any, b: any) => a.orderNumber - b.orderNumber);

    return acc;
  }, {});

  const artistInfo = [
    { id: 1, type: "거주지", data: ArtistData?.basicInfo.address.sido },
    { id: 2, type: "학력", data: ArtistData?.basicInfo.education },
    { id: 3, type: "매니저/소속사", data: ArtistData?.basicInfo.agency },
  ];

  const filteredAttachments = ArtistData?.attachments
    ?.filter(
      (attachment) =>
        attachment.type.code !== "THUMBNAIL" &&
        attachment.type.code !== "ADDITIONAL_VIDEO"
    )
    ?.sort((a, b) => {
      if (
        a.type.code === "ADDITIONAL_IMAGE" &&
        b.type.code !== "ADDITIONAL_IMAGE"
      ) {
        return -1;
      } else if (
        a.type.code !== "ADDITIONAL_IMAGE" &&
        b.type.code === "ADDITIONAL_IMAGE"
      ) {
        return 1;
      }
      return 0;
    });

  const ratingStartValue = () => {
    let result = [];
    for (let i = 0; i < 5; i++) {
      result.push(
        <div
          key={i + 1}
          onClick={() => {
            setFeedbackScore(i + 1);
          }}
        >
          {i + 1 <= feedbackScore ? <Star /> : <BlankStar />}
        </div>
      );
    }
    return result;
  };

  const readRatingStartValue = () => {
    let result = [];
    for (let i = 0; i < 5; i++) {
      result.push(
        <div key={i + 1}>
          {i + 1 <= ArtistData?.rating! ? <Star /> : <BlankStar />}
        </div>
      );
    }
    return result;
  };

  const mutate = useSubmitFeedback();

  const submitFeedback = () => {
    mutate.mutate(
      {
        id: Number(artistId),
        rating: feedbackScore,
        comments: review,
      },
      {
        onSuccess: () => {
          Toast.success("피드백을 보냈어요");
          refetch();
          setSendFeedback(false);
        },
      }
    );
  };

  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  const handlePrev = () => {
    // 이전으로 이동
    swiper?.slidePrev();
  };
  const handleNext = () => {
    // 이전으로 이동
    swiper?.slideNext();
  };

  const filteredAtt = ArtistData?.attachments!.filter(
    (attachment) => attachment.type.code !== "ADDITIONAL_VIDEO"
  );

  const { data: link } = useShareLink({
    id: profileId,
    type: "DELIVERY_PROFILE",
    period: "MONTH",
  });

  return (
    <>
      <HeaderWithBackButton
        phone={ArtistData?.phoneNumber}
        sms={ArtistData?.phoneNumber}
        email={ArtistData?.email}
        title=""
      >
        {/* <Bookmark className="cursor-pointer" /> */}
        <Share
          onClick={() => {
            setCopyModal(true);
            setProfileId(ArtistData?.deliveryProfileId!);
          }}
          className="cursor-pointer"
        />
      </HeaderWithBackButton>
      {isLoading || isError ? (
        <div>loading</div>
      ) : (
        <div className={`flex px-5 pb-24 flex-col items-start`}>
          <ScrollToTop />
          {copyModal && (
            <Modal
              onClose={() => {
                setProfileId(null);
                setCopyModal(false);
              }}
              title="공유하기"
              subTitle="공유된 링크는 30일 동안 유효해요."
              closeButton
            >
              <Button
                onClick={() => {
                  handleCopyClipBoard(
                    `${process.env.REACT_APP_URL}/artist/share?value=${link
                      ?.data.value!}&type=DELIVERY_PROFILE`
                  );
                  setCopyModal(false);
                }}
                className="w-full border border-Gray03 text-Gray05"
                text={"링크 복사하기"}
              ></Button>
            </Modal>
          )}
          {imageDetail && (
            <ImageModal
              onClose={() => setImageDetail(false)}
              className="max-w-[360px] w-full"
            >
              <Swiper
                onSwiper={(e: SwiperClass) => {
                  setSwiper(e);
                }}
                slidesPerView={1}
                className="relative"
              >
                {filteredAtt?.map((item, index) => {
                  return (
                    <SwiperSlide key={index} className="h-fit relative">
                      {MEDIA_URL && (
                        <img
                          src={`${MEDIA_URL}${item.url}`}
                          alt="IMAGES"
                          className="rounded-[10px] w-full max-w-[360px] h-[514px] mx-auto object-contain"
                        />
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <XButton
                onClick={() => {
                  setImageDetail(false);
                }}
                className="absolute cursor-pointer top-4 right-4 z-50"
              />
              <ArrowLeft
                onClick={() => {
                  handlePrev();
                }}
                className="absolute top-2/4 left-0 z-50 cursor-pointer"
              />
              <ArrowLeft
                onClick={() => {
                  handleNext();
                }}
                className="absolute top-2/4 right-0 z-50 rotate-180 cursor-pointer"
              />
            </ImageModal>
          )}
          {ArtistData?.description && (
            <div className="mb-5 w-full p-5 rounded-[10px] bg-Gray01 border border-Gray03">
              <div className="mb-4 text-Gray09 BBody18">
                💌{ArtistData?.profileName}님이 보낸 프로필
              </div>
              <div className="RBody16">{ArtistData?.description}</div>
            </div>
          )}
          <div className="w-full flex flex-col gap-2">
            <div>
              <div className="BBody14 text-Gray05">
                {ArtistData?.basicInfo.job.label}
              </div>
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <div className="BBody20">{ArtistData?.profileName}</div>
                  {ArtistData?.snsInfo.instagram && (
                    <img
                      onClick={() => {
                        handleOpenNewTab(ArtistData?.snsInfo.instagram!);
                      }}
                      className="cursor-pointer"
                      src={Insta}
                      alt="instagram"
                    />
                  )}
                  {ArtistData?.snsInfo.youtube && (
                    <img
                      onClick={() => {
                        handleOpenNewTab(ArtistData?.snsInfo.youtube!);
                      }}
                      className="cursor-pointer"
                      src={Youtue}
                      alt="youtube"
                    />
                  )}
                </div>
              </div>
              <div className="flex mb-2 items-center RBody16 gap-2">
                <div>{`${
                  ArtistData?.birthDate.split("-")[0]
                }년생 (${calculateAge()}세)`}</div>
                <div>{`${ArtistData?.basicInfo.height}cm`}</div>
                <div>{`${ArtistData?.basicInfo.weight}kg`}</div>
              </div>
              <div className="flex flex-wrap gap-x-2 RBody16 text-Blue04">
                {ArtistData?.hashtags?.map((item) => {
                  return <div key={item.id}>#{item.name}</div>;
                })}
              </div>
            </div>
            <div>
              {MEDIA_URL && (
                <img
                  onClick={() => {
                    setImageDetail(true);
                  }}
                  src={`${MEDIA_URL}${ArtistData?.thumbnailUrl}`}
                  alt=""
                  className="w-full h-[450px] object-cover rounded-[10px]"
                />
              )}
              <div className="w-full mt-2">
                <Swiper
                  autoplay={{ delay: 2000, disableOnInteraction: false }}
                  loop={true}
                  modules={[Autoplay]}
                  spaceBetween={8}
                  slidesPerView={filteredAttachments?.length! < 4 ? 3 : "auto"}
                >
                  {filteredAttachments?.map((item, idx) => {
                    return (
                      <SwiperSlide
                        className={
                          filteredAttachments.length! < 4 ? "w-full" : "!w-fit"
                        }
                        onClick={() => {
                          setImageDetail(true);
                        }}
                        key={idx}
                      >
                        <div className="w-full">
                          {MEDIA_URL && (
                            <img
                              src={`${MEDIA_URL}${item.url}`}
                              alt="IMAGES"
                              className="w-[114px] h-[114px] rounded-[10px] object-cover"
                            />
                          )}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {ArtistData?.attachments?.map((item, idx) => {
                if (item.type.code === "ADDITIONAL_VIDEO") {
                  return (
                    <video
                      key={item.id}
                      controls
                      className="w-full h-[186px] rounded-[10px]"
                    >
                      {MEDIA_URL && (
                        <source src={`${MEDIA_URL}${item.url}`}></source>
                      )}
                    </video>
                  );
                }
              })}
              {ArtistData?.videoLinks?.map((item, idx) => {
                let link = "";

                if (item.link.startsWith("https://www.youtube.com")) {
                  link = item.link.split("watch?v=")[1];
                } else {
                  link = item.link.split("?")[0].split("/")[3];
                }
                return (
                  <YouTube
                    key={item.orderNumber}
                    className={"w-full"}
                    opts={opt}
                    iframeClassName="youtubeRounded"
                    videoId={link}
                  />
                );
              })}
            </div>
          </div>
          <div className="flex-1 w-full">
            <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
            <div className="flex flex-wrap gap-y-10 items-center">
              {artistInfo.map((item) => {
                if (item?.id < 2 && item?.data)
                  return (
                    <div key={item.id}>
                      {item.data !== "0cm/0kg" ? (
                        <div className="flex flex-col w-[180px]">
                          <div className="RBody14 text-Gray05">{item.type}</div>
                          <div className="MBody16">{item.data}</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
              })}
              <div>
                {ArtistData?.hobbies.length !== 0 && (
                  <div className="flex flex-col w-[360px]">
                    <div className="RBody14 text-Gray05">특기</div>
                    <div className="MBody16 flex items-center flex-wrap">
                      {ArtistData?.hobbies.map((item, idx) => {
                        return (
                          <div key={item.id}>
                            {item.name}
                            {idx < ArtistData.hobbies.length - 1 ? "/" : ""}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              {artistInfo.map((item) => {
                if (item.id > 1 && item.data)
                  return (
                    <div className="flex flex-col w-[360px]" key={item.id}>
                      {item.data !== "" ? (
                        <div className="flex flex-col">
                          <div className="RBody14 text-Gray05">{item.type}</div>
                          <div className="MBody16">{item.data}</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
              })}
            </div>
            {ArtistData?.introduce !== "" && (
              <>
                <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
                <div className="w-full flex flex-col gap-2">
                  <div className="RBody16 text-Gray05">자기소개</div>
                  <div className="MBody16 max-h-[336px] whitespace-pre-wrap overflow-y-scroll">
                    {ArtistData?.introduce}
                  </div>
                </div>
              </>
            )}
            {ArtistData?.filmographies?.length !== 0 && (
              <>
                <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
                <div className="w-full flex flex-col gap-2">
                  <div className="RBody16 text-Gray05">필모그래피</div>
                  {ArtistData ? (
                    <div className="flex flex-col gap-4">
                      {Object.keys(groupedByYear)
                        .sort((a, b) => Number(b) - Number(a))
                        ?.map((year) => (
                          <div className="flex items-start gap-4" key={year}>
                            <div className="RBody14 text-Gray09">{year}</div>
                            <div className="flex flex-col items-baseline gap-4">
                              {groupedByYear[year].map((item: any) => (
                                <div key={item.id}>
                                  <div className="RBody14 text-Gray05">
                                    {item?.type?.label}
                                  </div>
                                  <div className="BBody16 mt-2 mb-1">
                                    {item.title}
                                  </div>
                                  <div className="BBody16 flex gap-1 text-Gray05 items-center">
                                    <div>{item?.role?.label}</div>
                                    <div>{item?.description}</div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>
          {feedback && (
            <BottomPopup
              onClose={() => {
                setFeedback(false);
              }}
            >
              <div className="BBody20">보낸 프로필 피드백</div>
              <div className="mt-4 mb-10">
                <div className="flex items-center gap-1">
                  <div className="RBody16 text-Gray09">별점</div>
                  <div className="BBody16 text-Blue04">
                    {ArtistData?.rating ? ArtistData?.rating : 0}/5
                  </div>
                </div>
                <div className="flex items-center mt-4 gap-4">
                  {readRatingStartValue()}
                </div>
              </div>
              <div className="p-4 bg-Gray01 rounded-[10px] RBody16">
                {ArtistData?.comments}
              </div>
            </BottomPopup>
          )}
          {sendFeedback && (
            <BottomPopup
              onClose={() => {
                setSendFeedback(false);
                setFeedbackScore(0);
              }}
            >
              <div className="BBody20">프로필 피드백</div>
              <div className="mt-4 mb-10">
                <div className="flex items-center gap-1">
                  <div className="RBody16 text-Gray09">별점</div>
                  <div className="BBody16 text-Blue04">
                    {feedbackScore ? feedbackScore : 0}/5
                  </div>
                </div>
                <div className="flex items-center mt-4 gap-4">
                  {ratingStartValue()}
                </div>
              </div>
              <div className="RBody16">이런 점이 좋아요🙂 / 아쉬워요😔</div>
              <Textarea
                onChange={(e) => {
                  setReview(e.target.value.trim());
                }}
                value={review}
                className="h-[105px] my-4"
              ></Textarea>
              <div className="RBody14 text-Gray05">
                타인을 비방하는 내용은 --돼요
              </div>
              <ButtonBackground>
                <Button
                  onClick={() => {
                    submitFeedback();
                  }}
                  disabled={feedbackScore === 0}
                  className="w-full bg-Blue04 text-Gray00 mt-4"
                  text={"피드백 보내기"}
                />
              </ButtonBackground>
            </BottomPopup>
          )}

          {ArtistData?.status.code === "CANCELED" ||
          ArtistData?.status.code === "REFUNDED" ? (
            ""
          ) : (
            <BottomButtonPopup
              onClick={() => {
                if (
                  ArtistData?.status.code === "DELIVERED" ||
                  ArtistData?.status.code === "READ"
                ) {
                  setSendFeedback(true);
                } else {
                  setFeedback(true);
                }
              }}
              onClose={() => {}}
            >
              <div className="flex flex-col pt-2 justify-center items-center MBody14 cursor-pointer text-Gray00">
                {ArtistData?.status.code === "DELIVERED" ||
                ArtistData?.status.code === "READ" ? (
                  <SendFeedback />
                ) : (
                  <Feedback />
                )}

                <div>
                  {ArtistData?.status.code === "DELIVERED" ||
                  ArtistData?.status.code === "READ"
                    ? "피드백 보내기"
                    : "보낸 피드백 보기"}{" "}
                </div>
              </div>
            </BottomButtonPopup>
          )}
        </div>
      )}
    </>
  );
};

export default ArtistFeedback;
