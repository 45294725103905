import React from "react";
import { ReactComponent as Point } from "assets/point.svg";

interface PointType {
  price?: string;
  onClick?: () => void;
  selected?: boolean
}

const PointCharge = ({ price, onClick, selected }: PointType) => {
  return (
    <div onClick={onClick} className={`cursor-pointer flex items-center justify-between rounded-[10px] px-4 py-3 border  ${selected ? "border-Blue04 bg-Blue01" : "border-Gray03 bg-Gray00"}`}>
      <div className="flex text-Gray09 MBody16 gap-2.5 items-center justify-between">
        <Point />
        <div>{Number(price).toLocaleString("ko-KR")} 포인트</div>
      </div>
      <div className="text-Gray09 BBody18">
        {Number(price).toLocaleString("ko-KR")}원
      </div>
    </div>
  );
};

export default PointCharge;
