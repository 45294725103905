import React from "react";
import { ReactComponent as ChipClose } from "assets/chipClose.svg";

interface ChipType {
  title: string;
  onClick?: () => void;
  selected?: boolean;
  closeButton?: boolean;
  className?: string;
}

const Chip = ({
  title,
  className,
  selected,
  onClick,
  closeButton,
}: ChipType) => {
  return (
    <div
      onClick={onClick}
      className={`${
        selected ? "border-Blue04 text-Blue04" : "border-Gray03 text-Gray05"
      } border items-center cursor-pointer MBody14 inline-flex justify-center gap-1 px-2 h-8 rounded-full ${className}`}
    >
      <div>{title}</div>
      {closeButton && <ChipClose />}
    </div>
  );
};

export default Chip;
