import React, { useEffect } from "react";
import ContentWrap from "components/ContentWrap";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import { ReactComponent as Edit } from "../assets/editButton.svg";

import NoProfile from "../assets/noProfile.png";

import Insta from "../assets/Instagram_black.png";
import Youtue from "../assets/YouTube_black.png";
import { Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { info } from "store/auth/atom";
import { Swiper, SwiperSlide } from "swiper/react";
import YouTube from "react-youtube";
import { useMyPage } from "api/Mypage/Mypage";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import Button from "components/Button";

const ArtistMy = () => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(info);

  const { data, isLoading, isError, refetch } = useMyPage();

  const ArtistData = data?.data.data;

  const calculateAge = () => {
    const currentYear = new Date().getFullYear();
    const calculatedAge =
      currentYear - Number(ArtistData?.birthDate.split("-")[0]);
    return calculatedAge;
  };

  const handleOpenNewTab = (url: string) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  const imageType = (type: string) => {
    return ArtistData?.attachments.filter(
      (image) => image.type.code === type
    )[0];
  };

  const opt = {
    height: "171",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  const groupedByYear = ArtistData?.filmographies.reduce((acc: any, item) => {
    const year = item.releaseYear;
    if (!acc[year]) {
      acc[year] = [];
    }

    acc[year].push(item);

    acc[year].sort((a: any, b: any) => a.orderNumber - b.orderNumber);

    return acc;
  }, {});

  const artistInfo = [
    { id: 1, type: "거주지", data: ArtistData?.basicInfo.address.sido },
    { id: 2, type: "학력", data: ArtistData?.basicInfo.education },
    { id: 3, type: "매니저/소속사", data: ArtistData?.basicInfo.agency },
  ];

  const filteredAttachments = ArtistData?.attachments
    ?.filter(
      (attachment) =>
        attachment.type.code !== "THUMBNAIL" &&
        attachment.type.code !== "ADDITIONAL_VIDEO"
    )
    ?.sort((a, b) => {
      if (
        a.type.code === "ADDITIONAL_IMAGE" &&
        b.type.code !== "ADDITIONAL_IMAGE"
      ) {
        return -1;
      } else if (
        a.type.code !== "ADDITIONAL_IMAGE" &&
        b.type.code === "ADDITIONAL_IMAGE"
      ) {
        return 1;
      }
      return 0;
    });

  return (
    <>
      <HeaderWithBackButton title={""}>
        <div
          onClick={() => navigate("/artist/profile/edit")}
          className="text-Blue04 BBody16 cursor-pointer"
        >
          수정
        </div>
      </HeaderWithBackButton>
      {isLoading || isError ? (
        <div>loading</div>
      ) : (
        <div>
          {ArtistData === null ? (
            <div className="text-center px-5 mx-auto flex-col pb-[70px]">
              <img
                className="w-[60px] h-[60px] mx-auto"
                src={NoProfile}
                alt="NoProfile"
              />
              <div className="mt-4 mb-10 MBody16 text-Gray05 whitespace-normal">
                아직 프로필이 없어요.
                <br />
                프로필을 등록하면 캐스팅을 시작할 수 있어요!
              </div>
              <Button
                className="px-4 py-3 text-Gray00 bg-Blue04 border-none"
                text={"프로필 등록하기"}
                onClick={() => {
                  navigate("/artist/profile");
                }}
              />
            </div>
          ) : (
            <div className="flex px-5 pb-24 flex-col items-start">
              <div className="w-full flex flex-col gap-2">
                <div className="BBody14 text-Gray05">
                  {ArtistData?.basicInfo.job.label}
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                    <div className="BBody20">{ArtistData?.profileName}</div>

                    {ArtistData?.snsInfo.instagram && (
                      <img
                        onClick={() => {
                          if (
                            ArtistData?.snsInfo.instagram.startsWith(
                              "https://www.instagram.com/"
                            )
                          ) {
                            handleOpenNewTab(ArtistData?.snsInfo.instagram!);
                          } else {
                            handleOpenNewTab(
                              `https://www.instagram.com/${ArtistData?.snsInfo
                                .instagram!}`
                            );
                          }
                        }}
                        className="cursor-pointer"
                        src={Insta}
                        alt="instagram"
                      />
                    )}
                    {ArtistData?.snsInfo.youtube && (
                      <img
                        onClick={() => {
                          handleOpenNewTab(ArtistData?.snsInfo.youtube!);
                        }}
                        className="cursor-pointer"
                        src={Youtue}
                        alt="youtube"
                      />
                    )}
                  </div>
                </div>
                <div className="flex mb-2 items-center RBody16 gap-2">
                  <div>{`${
                    ArtistData?.birthDate.split("-")[0]
                  }년생 (${calculateAge()}세)`}</div>
                  <div>{`${ArtistData?.basicInfo.height}cm`}</div>
                  <div>{`${ArtistData?.basicInfo.weight}kg`}</div>
                </div>
                <div>
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}${ArtistData?.thumbnailUrl}`}
                    alt=""
                    className="w-full h-[450px] object-cover rounded-[10px]"
                  />
                  <div className="w-full mt-2">
                    <Swiper
                      autoplay={{ delay: 2000, disableOnInteraction: false }}
                      loop={true}
                      modules={[Autoplay]}
                      spaceBetween={8}
                      slidesPerView={
                        filteredAttachments?.length! < 4 ? 3 : "auto"
                      }
                    >
                      {filteredAttachments?.map((item, idx) => {
                        return (
                          <SwiperSlide
                            className={
                              filteredAttachments.length! < 4
                                ? "w-full"
                                : "!w-fit"
                            }
                            key={idx}
                          >
                            <div className="w-full">
                              <img
                                src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                                alt="IMAGES"
                                className="w-[114px] h-[114px] rounded-[10px] object-cover"
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  {ArtistData?.attachments?.map((item, idx) => {
                    if (item.type.code === "ADDITIONAL_VIDEO") {
                      return (
                        <video
                          key={item.id}
                          controls
                          className="w-full h-[186px] rounded-[10px]"
                        >
                          <source
                            src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                          ></source>
                        </video>
                      );
                    }
                  })}
                  {ArtistData?.videoLinks?.map((item, idx) => {
                    let link = "";

                    if (item.link.startsWith("https://www.youtube.com")) {
                      link = item.link.split("watch?v=")[1];
                    } else {
                      link = item.link.split("?")[0].split("/")[3];
                    }
                    return (
                      <YouTube
                        key={item.orderNumber}
                        className={"w-full"}
                        opts={opt}
                        iframeClassName="youtubeRounded"
                        videoId={link}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="flex-1 w-full">
                <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
                <div className="flex flex-wrap gap-y-10 items-center">
                  {artistInfo.map((item) => {
                    if (item?.id < 2 && item?.data)
                      return (
                        <div key={item.id}>
                          {item.data !== "0cm/0kg" ? (
                            <div className="flex flex-col w-[180px]">
                              <div className="RBody14 text-Gray05">
                                {item.type}
                              </div>
                              <div className="MBody16">{item.data}</div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                  })}
                  <div>
                    {ArtistData?.hobbies.length !== 0 && (
                      <div className="flex flex-col w-[360px]">
                        <div className="RBody14 text-Gray05">특기</div>
                        <div className="MBody16 flex items-center flex-wrap">
                          {ArtistData?.hobbies.map((item, idx) => {
                            return (
                              <div key={item.id}>
                                {item.name}
                                {idx < ArtistData.hobbies.length - 1 ? "/" : ""}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  {artistInfo.map((item) => {
                    if (item.id > 1 && item.data)
                      return (
                        <div className="flex flex-col w-[360px]" key={item.id}>
                          {item.data !== "" ? (
                            <div className="flex flex-col">
                              <div className="RBody14 text-Gray05">
                                {item.type}
                              </div>
                              <div className="MBody16">{item.data}</div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                  })}
                </div>
                {ArtistData?.introduce !== "" && (
                  <>
                    <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
                    <div className="w-full flex flex-col gap-2">
                      <div className="RBody16 text-Gray05">자기소개</div>
                      <div className="MBody16 max-h-[336px] whitespace-pre-wrap overflow-y-scroll">
                        {ArtistData?.introduce}
                      </div>
                    </div>
                  </>
                )}
                {ArtistData?.filmographies?.length !== 0 && (
                  <>
                    <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
                    <div className="w-full flex flex-col gap-2">
                      <div className="RBody16 text-Gray05">필모그래피</div>
                      {ArtistData ? (
                        <div className="flex flex-col gap-4">
                          {Object.keys(groupedByYear)
                            .sort((a, b) => Number(b) - Number(a))
                            ?.map((year) => (
                              <div
                                className="flex items-start gap-4"
                                key={year}
                              >
                                <div className="RBody14 text-Gray09">
                                  {year}
                                </div>
                                <div className="flex flex-col items-baseline gap-4">
                                  {groupedByYear[year].map((item: any) => (
                                    <div key={item.id}>
                                      <div className="RBody14 text-Gray05">
                                        {item?.type?.label}
                                      </div>
                                      <div className="BBody16 mt-2 mb-1">
                                        {item.title}
                                      </div>
                                      <div className="BBody16 flex gap-1 text-Gray05 items-center">
                                        <div>{item?.role?.label}</div>
                                        <div>{item?.description}</div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ArtistMy;
