import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import ContentWrap from "components/ContentWrap";
import SearchBox from "components/SearchBox";
import StaffCard from "components/StaffCard";
import { useStaffs } from "api/Profile/Profile";
import Skeleton from "components/Skeleton";
import { useInView } from "react-intersection-observer";
import Checkbox from "components/Checkbox";
import useScrollRestoration from "hooks/useScrollRestoration";
import Header from "components/Header";
import ReactVirtuoso from "components/ReactVirtuoso";

const StaffList = () => {
  const navigate = useNavigate();
  const { scrollRef } = useScrollRestoration();
  const [selectedType, setSelectedType] = useState<SearchType>("name");
  const [selectedJob, setSelectedJob] = useState("CASTING_DIRECTOR");
  const [sort, setSort] = useState("createdAt,desc");
  const [isCasting, setIsCasting] = useState(false);
  const [searchValues, setSearchValues] = useState<Record<SearchType, string>>({
    name: "",
    filmoTitle: "",
    agency: "",
  });
  const [debouncedSearchValues, setDebouncedSearchValues] =
    useState(searchValues);
  type SearchType = "name" | "filmoTitle" | "agency";

  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValues(searchValues);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValues]);

  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useStaffs({
      role: selectedJob,
      sort: sort,
      name: debouncedSearchValues.name,
      filmographyTitle: debouncedSearchValues.filmoTitle,
      agency: debouncedSearchValues.agency,
      isCasting: isCasting,
    });

  const loadMore = useCallback(() => {
    return setTimeout(() => {
      fetchNextPage();
    }, 500);
  }, []);

  const job = [
    { id: 0, title: "캐스팅디렉터", role: "CASTING_DIRECTOR" },
    { id: 1, title: "매니저", role: "MANAGER" },
    { id: 2, title: "연출", role: "DIRECTOR" },
    { id: 3, title: "제작", role: "PRODUCER" },
    { id: 4, title: "광고", role: "AGENCY" },
    { id: 5, title: "작가", role: "WRITER" },
  ];

  const options = [
    { id: 0, title: "이름", value: "name" },
    { id: 1, title: "작품", value: "filmoTitle" },
    { id: 2, title: "소속사", value: "agency" },
  ];

  const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSearchValues({
      name: "",
      filmoTitle: "",
      agency: "",
    });

    setSelectedType(value as SearchType);
  };

  const allLists = useMemo(
    () => (data ? data?.pages?.flatMap((page) => page.data.content) : []),
    [data]
  );

  return (
    <>
      <Header title="STAFF" />
      <div className="relative mt-2 w-full grid grid-cols-3 border-y-[0.5px] border-Gray03">
        {job.map((item, index) => {
          return (
            <div
              onClick={() => {
                setSelectedJob(item.role);
              }}
              key={item.id}
              className={`MBody14 h-12 flex cursor-pointer items-center justify-center nonDragable hover:bg-Gray01/50 ${
                selectedJob === item.role ? "text-Gray09 bg-Gray01/50" : "text-Gray05"
              } ${
                index % 3 === 0
                  ? "border-l-0"
                  : "border-l-[0.5px] border-Gray03"
              } ${index >= job.length - 3 ? "border-b-0" : "border-b-[0.5px]"}`}
            >
              {item.title}
            </div>
          );
        })}
      </div>

      <div className="pb-24 px-5">
        <div className="flex items-center gap-4 mt-4">
          <SearchBox
            onDropDownChange={onTypeChange}
            onChange={(e) => {
              const value = e.target.value;
              setSearchValues({
                ...searchValues,
                [selectedType]: value,
              });
            }}
            value={searchValues[selectedType]}
            placeholder="이름, 작품, 소속사"
          >
            {options.map((item) => {
              return (
                <option key={item.id} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </SearchBox>
        </div>
        {data?.pages[0].data.content.length === 0 ? (
          <div className="mt-36 items-center text-center">
            <div className="text-Gray05 RBody14">등록된 스탭이 없어요.</div>
          </div>
        ) : (
          <div className="w-full">
            <div className="flex items-center my-4 justify-between">
              <select
                onChange={(e) => {
                  setSort(e.target.value);
                }}
              >
                <option value="createdAt,desc">등록순</option>
                <option value="updatedAt,desc">최신순</option>
              </select>
              <Checkbox
                id="nowCasting"
                onChange={(e) => {
                  setIsCasting(e.target.checked);
                }}
                checked={isCasting}
                title={"캐스팅중"}
              />
            </div>
            {isLoading ? (
              <div>
                {Array.from({ length: 10 }).map((_, index) => (
                  <Skeleton key={index} type="STAFF_LIST" />
                ))}
              </div>
            ) : (
              <div className="pb-[142px]">
                <ReactVirtuoso
                  isLoading={isLoading}
                  useWindowScroll
                  data={isLoading ? Array.from({ length: 1 }) : allLists}
                  overscan={100}
                  hasNextPage={hasNextPage}
                  isFetchingNextPage={isFetchingNextPage}
                  fetchNextPage={() => loadMore()}
                  itemContent={(index, item) => {
                    return (
                      <div className={`${index !== 0 && "mt-4"}`}>
                        <StaffCard
                          key={item.profileId}
                          isCasting={item?.isCasting}
                          image={item?.thumbnailUrl}
                          role={item?.role.label}
                          agency={item?.agency}
                          name={item?.profileName}
                          onClick={() => navigate(`${item.profileId}`)}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default StaffList;
