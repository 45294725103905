import React, { useEffect, useState } from "react";
import {
  useMyCastingCalls,
  useMyProductionCastingCalls,
  useProductions,
} from "api/Mypage/Mypage";
import Button from "components/Button";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { ReactComponent as SelectCastingCall } from "assets/selecCastingCall.svg";
import { useNavigate } from "react-router-dom";
import { useReceivedProfiles } from "api/DeliveryProfile/DeliveryProfile";
import BottomButtonPopup from "components/BottomButtonPopup";
import BottomPopup from "components/BottomPopup";
import { Toast } from "hooks/useToast";
import { useShortlistCasting } from "api/CastingStaff/CastingStaff";
import { useQueryClient } from "react-query";
import Thumnail from "assets/thumnail.png";
import { useInView } from "react-intersection-observer";
import ArtistListCard from "components/ArtistListCard";
import { splitBirth } from "utils/onBirth";
import ButtonBackground from "components/ButtonBackground";
import { innderHeightValue } from "store/auth/atom";
import { useRecoilState } from "recoil";

const ReceivedProfile = () => {
  const navigate = useNavigate();
  const [innderHeight, setInnderHeight] =
    useRecoilState<number>(innderHeightValue);

  const [height, setHeight] = useState(innderHeight - 165);
  const queryClient = useQueryClient();
  const [selectedCastingCallType, setSelectedCastingCallType] = useState(0);
  const [selectedList, setSelectedList] = useState<number[]>([]);
  const [selectMode, setSelectMode] = useState(false);
  const [selectCastingCall, setSelectCastingCall] = useState(false);
  const [accordionOpenId, setAccordionOpenId] = useState<number | null>(null);
  const [productId, setProductId] = useState<number | null>(null);
  const [selectedCastingCall, setSelectedCastingCall] = useState<number | null>(
    null
  );

  // 받은 프로필
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const { data: received, fetchNextPage, hasNextPage } = useReceivedProfiles();
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  // 작품
  const { ref: ProductRef, inView: ProductionInView } = useInView({
    threshold: 0.5,
  });
  const {
    data: ProductionData,
    fetchNextPage: ProductionNext,
    hasNextPage: ProductuinHas,
  } = useProductions({ status: "ACTIVE" });

  useEffect(() => {
    if (ProductionInView && ProductuinHas) {
      ProductionNext();
    }
  }, [ProductionInView]);

  // 작품 미분류
  const { ref: CastingCallRef, inView: CastingCallInView } = useInView({
    threshold: 0.5,
  });
  const {
    data: UnclassifiedData,
    fetchNextPage: UnclassifiedNext,
    hasNextPage: UnclassifiedHas,
  } = useMyCastingCalls({ isWithProduction: false, status: "ACTIVE" });

  useEffect(() => {
    if (CastingCallInView && UnclassifiedHas) {
      UnclassifiedNext();
    }
  }, [CastingCallInView]);

  // 작품 내부 공고
  const {
    ref: ProductinWithCastingCallRef,
    inView: ProductinWithCastingCallInView,
  } = useInView({ threshold: 0.5 });
  const {
    data: productWidthCastingCallData,
    fetchNextPage: productWithCastingcallNext,
    hasNextPage: productWithCastingcallHas,
  } = useMyProductionCastingCalls({ id: productId!, status: "ACTIVE" });

  useEffect(() => {
    if (ProductinWithCastingCallInView && productWithCastingcallHas) {
      productWithCastingcallNext();
    }
  }, [ProductinWithCastingCallInView]);

  const mutate = useShortlistCasting();

  const handleShortlist = () => {
    const data = {
      castingCallId: selectedCastingCall,
      deliveryProfileIds: selectedList,
    };
    mutate.mutate(data, {
      onSuccess: (res) => {
        Toast.success("프로필을 이동했어요.");
        queryClient.removeQueries("receivedProfile");
        setSelectCastingCall(false);
      },
    });
  };

  const suggestCastingCallType = [
    { id: 0, title: "작품별" },
    { id: 1, title: "작품미분류" },
  ];

  return (
    <>
      <HeaderWithBackButton title="받은 프로필">
        <div
          onClick={() => {
            setSelectMode((selectMode) => !selectMode);
            if (selectMode) {
              setSelectedList([]);
            }
          }}
          className="text-Blue04 min-w-[65px] BBody14 cursor-pointer"
        >
          {received?.pages[0].data.content.length !== 0 && (
            <>{selectMode ? "취소" : "후보자 선정"}</>
          )}
        </div>
      </HeaderWithBackButton>
      <div className="px-5 pt-5 pb-24">
        <div className="grid grid-cols-3 gap-y-4 gap-x-2">
          {received?.pages.map((item: any) => {
            return item.data.content.map((receivedItem: any) => {
              return (
                <ArtistListCard
                  onClick={() => {
                    if (selectMode) {
                      if (
                        selectedList.includes(receivedItem.deliveryProfileId)
                      ) {
                        setSelectedList(
                          selectedList.filter(
                            (id) => id !== receivedItem.deliveryProfileId
                          )
                        );
                      } else {
                        setSelectedList([
                          ...selectedList,
                          receivedItem.deliveryProfileId,
                        ]);
                      }
                    } else {
                      navigate(
                        `/staff/feedback/${receivedItem.deliveryProfileId}`
                      );
                    }
                  }}
                  selected={selectedList.includes(
                    receivedItem.deliveryProfileId
                  )}
                  selectMode={selectMode}
                  subTitle={`${splitBirth(receivedItem.birthDate)}`}
                  key={receivedItem?.deliveryProfileId}
                  src={receivedItem.thumbnailUrl}
                  title={receivedItem.profileName}
                  isOffered={receivedItem.isOffered}
                />
              );
            });
          })}
          <div ref={ref} />
        </div>
        {selectCastingCall && (
          <BottomPopup
            className="!h-full"
            onClose={() => setSelectCastingCall(false)}
          >
            <div className="BBody20 mb-4">공고선택</div>
            <div className="w-full flex bg-Gray03 items-center border-Gray03 border rounded-[10px]">
              {suggestCastingCallType.map((item, i) => {
                return (
                  <div
                    key={item.id}
                    onClick={() => {
                      setSelectedCastingCallType(item.id);
                      setAccordionOpenId(null);
                      setSelectedCastingCall(null);
                    }}
                    className={`flex-1 text-center BBody14 cursor-pointer rounded-[10px] py-2 ${
                      selectedCastingCallType === item.id
                        ? "text-Gray09 bg-Gray00"
                        : "text-Gray05"
                    }`}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
            <div className="pt-4">
              {selectedCastingCallType === 0 ? (
                <div
                  style={{ height: `${height}px` }}
                  className="flex flex-col gap-4 overflow-y-auto pb-[64px]"
                >
                  {ProductionData?.pages?.map((item) => {
                    return item.data.content.map((item: any) => {
                      // 작품
                      return (
                        <div key={item.productionId}>
                          <div
                            key={item.productionId}
                            onClick={() => {
                              if (item.productionId === accordionOpenId) {
                                setAccordionOpenId(null);
                                setProductId(0);
                              } else {
                                setSelectedCastingCall(null);
                                setProductId(item.productionId);
                                setAccordionOpenId(item.productionId);
                              }
                            }}
                            className="flex cursor-pointer items-center gap-4 w-full relative"
                          >
                            <img
                              className="w-12 h-12 rounded"
                              src={
                                item.thumbnailUrl
                                  ? `${process.env.REACT_APP_MEDIA_URL}${item.thumbnailUrl}`
                                  : Thumnail
                              }
                              alt=""
                            />
                            <div>
                              <div className="text-Gray05 MCaption12">
                                {item.format.label}
                              </div>
                              <div className="MBody18 text-Gray09 max-w-[223px] text-ellipsis overflow-hidden whitespace-nowrap">
                                {item.title}
                              </div>
                            </div>
                          </div>
                          <div>
                            {item.productionId === accordionOpenId && (
                              <div
                                className={`max-h-[500px] overflow-y-auto bg-Gray01 mt-4 py-2 px-5 border-t border-Gray03`}
                              >
                                {productWidthCastingCallData?.pages[0].data
                                  .content?.length !== 0 ? (
                                  <div className="flex flex-col gap-4">
                                    {productWidthCastingCallData?.pages.map(
                                      (item: any, i) => {
                                        return item.data.content.map(
                                          (pItem: any) => {
                                            // 작품을 클릭해서 열면 나오는 공고
                                            return (
                                              <div
                                                key={pItem?.castingCallId}
                                                onClick={() => {
                                                  setSelectedCastingCall(
                                                    pItem?.castingCallId
                                                  );
                                                }}
                                                className="cursor-pointer"
                                              >
                                                <div
                                                  className={`BBody16 mb-2 max-w-[333px] text-ellipsis overflow-hidden whitespace-nowrap ${
                                                    pItem.castingCallId ===
                                                    selectedCastingCall
                                                      ? "text-Blue04"
                                                      : "text-Gray09 "
                                                  }`}
                                                >
                                                  {pItem.title}
                                                </div>
                                                <div className="text-Gray05 RBody14">
                                                  {pItem.deadlineDate
                                                    ? pItem.deadlineDate
                                                    : "캐스팅 확정 시 마감"}
                                                </div>
                                              </div>
                                            );
                                          }
                                        );
                                      }
                                    )}
                                  </div>
                                ) : (
                                  <div className="RBody16">
                                    캐스팅 중인 공고가 없어요.
                                  </div>
                                )}
                                <div ref={ProductinWithCastingCallRef} />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    });
                  })}
                  <div ref={ProductRef} />
                </div>
              ) : (
                <div
                  style={{ height: `${height}px` }}
                  className="flex flex-col gap-4 overflow-y-auto pb-[64px] h-full"
                >
                  {UnclassifiedData?.pages?.map((item) => {
                    // 작품 미분류
                    return item.data.content.map((myItem: any) => {
                      return (
                        <div
                          key={myItem.castingCallId}
                          onClick={() => {
                            setSelectedCastingCall(myItem?.castingCallId);
                          }}
                          className={`cursor-pointer ${
                            selectedCastingCall === myItem.castingCallId
                              ? "text-Blue04"
                              : "text-Gray09 "
                          }`}
                        >
                          <div className="BBody16">{myItem.title}</div>
                          <div className="RBody14 text-Gray05">
                            {myItem.deadlineDate
                              ? myItem.deadlineDate
                              : "캐스팅 확정 시"}{" "}
                            마감
                          </div>
                        </div>
                      );
                    });
                  })}
                  <div ref={CastingCallRef} />
                </div>
              )}
            </div>
            <ButtonBackground>
              <div className="flex gap-4 w-full">
                <Button
                  onClick={() => {
                    setSelectCastingCall(false);
                  }}
                  text={"닫기"}
                  className="w-full text-Gray05 flex-1 bg-Gray03 "
                />
                <Button
                  disabled={selectedCastingCall === null}
                  onClick={() => {
                    handleShortlist();
                  }}
                  text={"이동하기"}
                  className="w-full bg-Blue04 flex-1 text-Gray00 MBody16"
                />
              </div>
            </ButtonBackground>
          </BottomPopup>
        )}
        {selectMode && (
          <BottomButtonPopup
            onClose={() => {
              setSelectMode(false);
            }}
          >
            <div
              onClick={() => {
                if (selectedList.length !== 0) {
                  setSelectCastingCall(true);
                  setSelectedCastingCall(null);
                  setAccordionOpenId(null);
                } else {
                  Toast.error("프로필을 선택해주세요");
                }
              }}
              className="flex flex-col pt-2 justify-center items-center MBody14 cursor-pointer text-Gray00"
            >
              <SelectCastingCall />
              <div>공고선택</div>
            </div>
          </BottomButtonPopup>
        )}
      </div>
    </>
  );
};

export default ReceivedProfile;
