import React from "react";
import { ReactComponent as ArrowRight } from "assets/arrowRight.svg";

const pointCard = () => {
  return (
    <div>
      <div className="RBody14 text-Gray05">2024.09.02 00:00</div>
      <div className="flex items-center w-full justify-between">
        <div className="BBody16 text-Gray09">10,000포인트</div>
        <div className="flex cursor-pointer items-center gap-2 BBody16 mt-2 text-Blue04">
          <div>10,000원</div>
          <ArrowRight />
        </div>
      </div>
    </div>
  );
};

export default pointCard;