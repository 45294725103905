import React from "react";
import { ReactComponent as Back } from "assets/icon_back_wh.svg";
import { useNavigate } from "react-router-dom";

import MembershipImage from "assets/img_membership.png";
import Logo from "assets/CastingVote_logo_symbol.png";
import { FillButton } from "components/FillButton";

const Membership = () => {
  const navigate = useNavigate();


  const info = [
    {
      id: 0,
      title: "☝️ 업계 최초.",
      subTitle: "캐스팅 디렉터, 매니저 등 실무진 영입",
      descrption:
        "지옥에서 온 판사, 베테랑2, 셀러브리티,  사내맞선 등 다수 메이저 캐스팅 디렉터, 현직 매니저 등 엔터 업계 실무진 다수 영입",
    },
    {
      id: 1,
      title: "👊 업계 최다.",
      subTitle: "단독 캐스팅 공고 업데이트",
      descrption:
        "캐스팅보트에서만 확인할 수 있는 메이저 캐스팅 디렉터의 미공개 공고 업데이트",
    },
    {
      id: 2,
      title: "💪 업계 최고.",
      subTitle: "스탭 • 공고 프로필 딜리버리",
      descrption:
        "캐스팅보트와 계약된 스탭과 캐스팅 공고에 직접 프로필 보내고 캐스팅과 프로필 피드백 두 마리 잡기!",
    },
  ];

  return (
    <div
      // style={{ height: "calc(var(--vh, 1vh) * 100)" }}
      className="w-full pb-[103px] min-w-[280px] max-w-[400px] min-h-screen px-5 bg-[#1A1E28] mx-auto"
    >
      <div className="mx-auto px-5 py-2 fixed bg-[#1A1E28] left-2/4 top-0 z-50 -translate-x-2/4 max-w-[400px] w-full BBody20 text-Gray09 ">
        <div className="flex justify-between items-center">
          <Back
            className="cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className="absolute left-2/4 text-Gray00 BBody20 -translate-x-2/4 max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            멤버십
          </div>
        </div>
      </div>
      <div className="mx-auto w-full text-center pt-[111px] max-w-[207px]">
        <div className="flex items-center gap-1 justify-center">
          <img className="h-7" src={Logo} alt="logo"></img>
          <div className="text-Gray00 BBody20">CV 멤버십</div>
        </div>
        <div className="text-Gray03 mt-2 mb-10 MBody14">
          캐스팅 디렉터부터 단독 오디션까지, 캐스팅보트 한 곳에서!
        </div>
        <img className="w-[186px]" src={MembershipImage} alt="membershipItem" />
      </div>
      <div className="flex flex-col gap-4 mt-[84px]">
        {info.map((item, i) => {
          return (
            <div
              key={item.id}
              className="p-5 text-Gray00 bg-[#0E131E] rounded-[10px]"
            >
              <div className="BBody20">{item.title}</div>
              <div className="BBody16 mt-4 mb-2">{item.subTitle}</div>
              <div className="MBody14">{item.descrption}</div>
            </div>
          );
        })}
      </div>
      <div className="max-w-[400px] bg-[#1A1E28] w-full fixed bottom-0 left-2/4 -translate-x-2/4 pb-8 px-5">
        <FillButton
          variant="Membership"
          size={"B"}
          onClick={() => {
            navigate("/membership/subscribe");
          }}
          text="멤버십 시작하기"
        ></FillButton>
      </div>
    </div>
  );
};

export default Membership;
