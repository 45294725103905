import React, { useCallback, useEffect } from "react";
import ContentWrap from "components/ContentWrap";

import NoProfile from "../assets/noProfile.png";

import Button from "components/Button";
import StaffWorkCard from "components/StaffWorkCard";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { info } from "store/auth/atom";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useMyPage, useProductions } from "api/Mypage/Mypage";
import { useInView } from "react-intersection-observer";

const StaffMy = () => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(info);
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const { data } = useMyPage();
  const StaffData = data?.data.data;

  const sortedFilmo = StaffData?.filmographies.sort(
    (a, b) => Number(b.releaseYear) - Number(a.releaseYear)
  );

  const { data: prod, fetchNextPage, hasNextPage, refetch } = useProductions();
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return (
    <div>
      <HeaderWithBackButton title="내 프로필">
        {StaffData !== null && (
          <div
            onClick={() => navigate("/staff/profile/edit")}
            className="text-Blue04 BBody16 cursor-pointer"
          >
            수정
          </div>
        )}
      </HeaderWithBackButton>
      {StaffData === null ? (
        <div className="text-center mx-auto px-5 flex-col pb-24">
          <img
            className="w-[60px] h-[60px] mx-auto"
            src={NoProfile}
            alt="NoProfile"
          />
          <div className="mt-4 mb-10 MBody16 text-Gray05 whitespace-normal">
            아직 프로필이 없어요.
            <br />
            프로필을 등록하면 캐스팅을 시작할 수 있어요!
          </div>
          <Button
            className="px-4 py-3 text-Gray00 bg-Blue04 border-none"
            text={"프로필 등록하기"}
            onClick={() => {
              navigate("/staff/profile");
            }}
          />
        </div>
      ) : (
        <div className="flex px-5 pb-24 flex-col">
          <div>
            <div className="w-full items-start flex gap-7">
              <img
                src={`${process.env.REACT_APP_MEDIA_URL}${StaffData?.thumbnailUrl}`}
                alt="Thumnai"
                className="w-[160px] h-[196px] rounded-[10px] object-cover"
              />
              <div>
                <div className="flex items-center justify-between gap-2 BBody20 text-Gray09">
                  <div>
                    {StaffData?.profileName ? StaffData?.profileName : "홍길동"}
                  </div>
                </div>
                <div className="my-2 MCaption12 flex items-start flex-wrap text-Gray05">
                  <div>
                    {userInfo?.role === "MANAGER"
                      ? "매니저"
                      : userInfo?.role === "CASTING_DIRECTOR"
                      ? "캐스팅 디렉터"
                      : userInfo?.role === "DIRECTOR"
                      ? "연출"
                      : "프로듀서"}
                  </div>
                  <div className="flex items-center">
                    <div>•</div>
                    <div className="overflow-hidden text-ellipsis w-[91px]">
                      {StaffData?.basicInfo?.agency}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col max-h-[136px] overflow-y-auto gap-2">
                  {sortedFilmo?.map((item, i) => {
                    return (
                      <div key={i} className="flex items-center gap-2">
                        <div className="RBody14 text-Gray05">
                          {item?.releaseYear}
                        </div>
                        <div className="MBody14 text-Gray09">{item?.title}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="my-4">{/* <Chip title={"전체"}></Chip> */}</div>
            {prod?.pages[0]?.data.content.length !== 0 ? (
              <div className=" gap-x-4 gap-y-2 grid grid-cols-2">
                {prod?.pages.map((item: any, i) => {
                  return item.data.content.map((prodItem: any) => {
                    // 작품
                    return (
                      <StaffWorkCard
                        key={prodItem.productionId}
                        onClick={() =>
                          navigate(`/staff/filmo/${prodItem.productionId}`)
                        }
                        title={prodItem.title}
                        src={prodItem.thumbnailUrl}
                      />
                    );
                  });
                })}
              </div>
            ) : (
              <div className="mt-36 items-center text-center">
                <div className="text-Gray05 RBody14">
                  캐스팅 중인 공고가 없어요.
                </div>
              </div>
            )}
            <div ref={ref} />
          </div>
        </div>
      )}
    </div>
  );
};

export default StaffMy;
