import React, { Children } from "react";

interface InputType {
  placeholder?: string;
  type: string;
  value?: string | null | number;
  disabled?: boolean;
  inputMode?:
    | "search"
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal";
  defaultValue?: string;
  ref?: React.ForwardedRef<HTMLInputElement>;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onKeyUp?: (event: React.KeyboardEvent) => void;
  readonly?: boolean;
  imagePosition?: "L" | "R";
  children?: React.ReactNode;
}

const Input = ({
  type,
  placeholder,
  className,
  onChange,
  onClick,
  onKeyDown,
  onKeyUp,
  value,
  inputMode,
  disabled,
  readonly,
  ref,
  imagePosition,
  children,
}: InputType) => {
  return (
    <div className="relative">
      <input
        disabled={disabled}
        readOnly={readonly}
        onKeyPress={onKeyDown}
        onKeyUp={onKeyUp}
        ref={ref}
        onChange={onChange}
        onClick={onClick}
        inputMode={inputMode}
        type={type}
        value={value || ""}
        placeholder={placeholder}
        className={`relative border focus:border-Blue04 RBody16 w-full border-Gray03 box-border h-12 rounded-md px-4 ${
          imagePosition === "L" && "!pl-[52px]"
        } ${className}`}
      />
      {imagePosition === "L" && children ? children : ""}
    </div>
  );
};

export default Input;
