import React, { Children } from 'react';

interface Props {
    onChange?:(e:React.ChangeEvent<HTMLInputElement>) => void;
    maxLength?:string;
    currentLength?:number;
    multiple?: boolean;
    accept?: string;
    id:string;
    disabled?:boolean
    children?: React.ReactNode;
}

const ImgUpload = ({onChange, maxLength, disabled, currentLength, accept, multiple,id, children}:Props) => {
    
    return (
        <label htmlFor={id} className='cursor-pointer'>
            <input multiple={multiple} accept={accept} id={id} onChange={onChange} className='hidden' type="file"></input>
            <div>
            {children}
            </div>
        </label>
    );
};

export default ImgUpload;