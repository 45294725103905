import { atom } from "recoil";
import { AuthInfoResponse } from "type/auth";

export const info = atom<{ id: string; username: string; role: string }>({
  key: "info",
  default: {
    id: "",
    username: "",
    role: "",
  },
});

export const loginCheck = atom<boolean>({
  key: "loginCheck",
  default: false,
});

export const innderHeightValue = atom<number>({
  key: "innderHeightValue",
  default: window.innerHeight,
});

export const loadingState = atom({
  key: "loadingState",
  default: false,
});

export const authInfo = atom<{
  memberId?: null | number;
  userId?: string;
  birthDate?: string;
  gender: { code: string; label: string };
  name?: string;
  profileId?: null | number;
  profileName?: string;
  thumbnailUrl?: string;
  authorities?: string[];
  favoriteProfileIds?: number[];
  favoriteProductionIds?: number[];
  favoriteCastingCallIds?: number[];
}>({
  key: "AuthInfo",
  default: {
    memberId: null,
    userId: "",
    name: "",
    birthDate: "",
    gender: {
      code: "",
      label: "",
    },
    profileId: null,
    profileName: "",
    thumbnailUrl: "",
    authorities: [],
    favoriteProfileIds: [],
    favoriteProductionIds: [],
    favoriteCastingCallIds: [],
  },
});
