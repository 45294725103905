import React from "react";
import { ReactComponent as Tooltip } from "assets/Tooltip.svg";

interface TitleWrapType {
  title: string;
  subTitle?: string;
  children: React.ReactNode;
  className?: string;
  require?: boolean;
  tooltip?: boolean;
  T20?: boolean;
}

const TitleWrap = ({
  title,
  subTitle,
  className,
  tooltip,
  children,
  require,
}: TitleWrapType) => {
  return (
    <div>
      <div className={`flex items-center mb-2`}>
        <div className={`text-Gray09 RCaption14`}>{title}</div>
        {require && <div className="text-Blue04 BCaption14">*</div>}
        {tooltip && <Tooltip className="ml-0.5" />}
        {subTitle && (
          <div className="RCaption10 ml-1 text-Gray05">{subTitle}</div>
        )}
      </div>
      <div className="relative w-full">{children}</div>
    </div>
  );
};

export default TitleWrap;
