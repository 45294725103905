import useOutSideClick from "hooks/onOutSideClick";
import React, { useRef } from "react";
import { createPortal } from "react-dom";

interface ModalType {
  className?: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

function ModalContainer({ children }: { children: React.ReactNode }) {
  return createPortal(<>{children}</>, document.getElementById("modal")!);
}

const ImageModal = ({ className, children, onClose }: ModalType) => {
  const modalRef = useRef(null);

  const handleClose = () => {
    onClose?.();
  };

  useOutSideClick(modalRef, handleClose);
  return (
    <ModalContainer>
      <div className="fixed w-full h-full top-0 left-0 bg-Gray09 z-[9999]">
        <div
          ref={modalRef}
          className={`rounded-xl absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 ${className}`}
        >
          {children}
        </div>
      </div>
    </ModalContainer>
  );
};

export default ImageModal;
