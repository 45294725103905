import { AxiosResponse, AxiosError } from "axios";
import { Toast } from "hooks/useToast";
import {
  UseMutationResult,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";
import { ERROR } from "type/common";
import {
  ChangeProductionStatusPayload,
  ChangeProductionStatusResponse,
  getProduction,
  ProductionData,
  ProductionDetailResponse,
  ProductionQueryParams,
  ProductionResponse,
  UpdateProductionRequest,
  UpdateProductionResponse,
} from "type/staff";
import requestToCastingVote from "utils/requestToCastingVote";

// 작품 등록
const createProduction = ({
  title,
  format,
  description,
  directorName,
  companyName,
  attachmentId,
}: ProductionData) => {
  return requestToCastingVote({
    method: "POST",
    url: "productions",
    data: {
      title,
      format,
      description,
      directorName,
      companyName,
      attachmentId,
    },
  });
};
export const useCreateProductionMutation = (
  data: ProductionData
): UseMutationResult<
  AxiosResponse<ProductionResponse>,
  AxiosError<ERROR>,
  ProductionData
> => {
  const createProductionMutation = useMutation<
    AxiosResponse<ProductionResponse>,
    AxiosError<ERROR>,
    ProductionData
  >({
    mutationFn: createProduction,
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });

  return createProductionMutation;
};

// 작품 수정
const updateProduction = async (
  data: UpdateProductionRequest
): Promise<AxiosResponse<UpdateProductionResponse>> => {
  return requestToCastingVote.patch("productions", data);
};
export const useUpdateProduction = () => {
  return useMutation<
    AxiosResponse<UpdateProductionResponse>,
    AxiosError<ERROR>,
    UpdateProductionRequest
  >(updateProduction, {
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
};

// 작품 상태 변경
const changeProductionStatus = async (
  payload: ChangeProductionStatusPayload
): Promise<AxiosResponse<ChangeProductionStatusResponse>> => {
  return requestToCastingVote.patch("/productions/status", payload);
};
export const useChangeProductionStatusMutation = () => {
  return useMutation(changeProductionStatus, {
    onSuccess: () => {
      Toast.success("작품 상태가 성공적으로 변경되었습니다.");
    },
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
};

// 작품 목록 조회
const getProductions = (
  params: ProductionQueryParams
): Promise<AxiosResponse<getProduction>> => {
  return requestToCastingVote("productions", {
    params: {
      title: params.title,
      format: params.format,
      directorName: params.directorName,
      companyName: params.companyName,
      memberId: params?.memberId,
      page: params.page ?? 0,
      size: params.size ?? 20,
    },
  });
};
export const useProductions = (
  params: ProductionQueryParams
): UseQueryResult<AxiosResponse<getProduction>, AxiosError<ERROR>> => {
  return useQuery(["products", params], () => getProductions(params), {
    enabled: !!params.memberId,
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
};

const getStaffProductions = (params?: ProductionQueryParams) => {
  return requestToCastingVote({
    method: "GET",
    url: "/productions",
    params,
  }).then((res) => res.data);
};

export const useStaffProductions = (params: ProductionQueryParams) => {
  return useInfiniteQuery(
    ["products", params],
    ({ pageParam = 0 }) =>
      getStaffProductions({ page: pageParam, size: 20, ...params }),
    {
      getNextPageParam: (lastPage, allPosts) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
      enabled: !!params?.memberId,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 작품 상세 조회
const getProductionById = (
  id: number
): Promise<AxiosResponse<ProductionDetailResponse>> => {
  return requestToCastingVote(`productions/${id}`);
};
export const useProductionDetail = (
  id: number
): UseQueryResult<
  AxiosResponse<ProductionDetailResponse>,
  AxiosError<ERROR>
> => {
  return useQuery(["productionDetail", id], () => getProductionById(id), {
    enabled: !!id,
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
};
