import React from "react";

interface ButtonBgProps {
  children?: React.ReactNode;
}

const ButtonBackground = ({ children }: ButtonBgProps) => {
  return (
    <div className="max-w-[400px] flex items-center gap-2 bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 pb-8 px-5 z-50">
      {children}
    </div>
  );
};

export default ButtonBackground;
