import { useScrollRestorationWithVirtualization } from "hooks/useScrollRestorationWithVirtuoso";
import React, { ReactNode, useRef } from "react";
import { useLocation, useNavigationType } from "react-router";
import { VirtuosoHandle, ListItem, VirtuosoGrid } from "react-virtuoso";

interface ReactVirtuosoGridProps<T> {
  data: T[];
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  isLoading: boolean;
  itemContent: (index: number, postItem: any) => ReactNode;
  itemClassName?: string;
  listClassName?: string;
  overscan?: number;
  sleep?: number;
  useWindowScroll?: boolean;
}

const ReactVirtuosoGrid = <T,>({
  data,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  itemContent,
  itemClassName,
  listClassName,
  overscan,
  sleep = 0,
  useWindowScroll,
}: ReactVirtuosoGridProps<T>) => {
  const location = useLocation();
  const navigationType = useNavigationType();
  const scrollKey = `scrollIndex${location.pathname}`;
  const virtuosoRef = useRef<VirtuosoHandle | null>(null);

  useScrollRestorationWithVirtualization(virtuosoRef, isLoading);

  const handleItemsRendered = (items: {
    startIndex: number;
    endIndex: number;
  }) => {
    if (items.startIndex > 0) {
      const startIndex = items.startIndex;
      sessionStorage.setItem(scrollKey, String(startIndex));
    }
  };

  return (
    <VirtuosoGrid
      useWindowScroll={useWindowScroll}
      data={data}
      itemClassName={itemClassName}
      listClassName={listClassName}
      rangeChanged={(range) => {
        handleItemsRendered(range);
      }}
      itemContent={itemContent}
      endReached={() => {
        if (hasNextPage && !isFetchingNextPage) {
          fetchNextPage?.();
        }
      }}
      ref={virtuosoRef}
    />
  );
};

export default ReactVirtuosoGrid;
