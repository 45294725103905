import React from "react";

const Private = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">개인정보 처리방침</h1>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">제1조 (목적)</h2>
        <p className="text-gray-600 leading-relaxed">
          커넥션스튜디오(이하 '회사')는 회사가 제공하고자 하는 CastingVote 서비스(이하
          '서비스')를 이용하는 '이용자'의 정보(개인정보)를 보호하기 위해, 개인정보보호법,
          정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 '정보통신망법') 등 관련 법령을
          준수하고, 서비스 이용자의 개인정보보호 관련한 고충을 신속하고 원활하게 처리할 수 있도록
          하기 위하여 다음과 같이 개인정보처리방침을 수립·공개합니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제2조 (수집하는 개인정보의 항목 및 수집방법)
        </h2>
        <p className="text-gray-600 leading-relaxed mb-4">
          회사는 이용자의 회사 서비스에 대한 회원가입을 위하여 다음과 같은 정보를 수집합니다.
        </p>
        <ul className="list-disc list-inside text-gray-600">
          <li>회원가입 시: 이름, 성별, 나이, 생년월일, 비밀번호, 전화번호, 이메일, 인스타그램 등</li>
          <li>프로필 등록 시: 이름, 휴대폰번호, 이메일, 프로필 이미지, 자기소개, 직업, 경력 등</li>
          <li>유료정보 이용 시: 카드사명, 카드번호, 생년월일 등</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제3조 (개인정보의 제공, 처리위탁 및 국외이전)
        </h2>
        <p className="text-gray-600 leading-relaxed mb-4">
          회사는 개인정보를 제1조에서 고지한 범위 내에서만 이용하며, 이용자의 사전 동의 없이는 동
          범위를 초과하여 이용하지 않습니다.
        </p>
        <ul className="list-disc list-inside text-gray-600">
          <li>매각, 합병 시 개인정보 제공</li>
          <li>오디션/캐스팅 지원 시 개인정보 제공</li>
          <li>캐스팅 제안 서비스 이용 시 개인정보 제공</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제4조 (개인정보의 보유 및 이용기간)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          가입일로부터 서비스를 제공하는 기간 동안에만 개인정보를 보유하고 이용합니다.
        </p>
        <ul className="list-disc list-inside text-gray-600 mt-4">
          <li>계약 또는 청약철회 기록: 5년</li>
          <li>대금결제 기록: 5년</li>
          <li>소비자 불만 기록: 3년</li>
          <li>부정이용 기록: 5년</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제5조 (개인정보 파기절차 및 방법)
        </h2>
        <p className="text-gray-600 leading-relaxed mb-4">
          이용자의 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
          지체 없이 파기합니다.
        </p>
        <ul className="list-disc list-inside text-gray-600">
          <li>
            <strong>파기절차:</strong> 개인정보는 별도의 DB로 옮겨져 일정 기간 후 파기됩니다.
          </li>
          <li>
            <strong>파기방법:</strong> 전자적 파일은 기록을 재생할 수 없는 기술적 방법을 사용하여
            삭제합니다.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제6조 (이용자의 권리와 의무 및 행사방법)
        </h2>
        <ul className="list-disc list-inside text-gray-600">
          <li>이용자는 언제든지 자신의 개인정보를 조회하거나 수정할 수 있습니다.</li>
          <li>개인정보 수집·이용에 대한 동의를 철회할 수 있습니다.</li>
          <li>부정확한 정보 입력에 대한 책임은 이용자에게 있습니다.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제7조 (개인정보 보호를 위한 기술적·관리적 보호 대책)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 개인정보가 분실, 도난, 유출되지 않도록 다양한 보호 대책을 마련하고 있습니다.
        </p>
        <ul className="list-disc list-inside text-gray-600 mt-4">
          <li>비밀번호 암호화</li>
          <li>해킹 방지 시스템 구축</li>
          <li>개인정보 취급자 교육</li>
        </ul>
      </section>

      <section>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          제8조 (개인정보 보호책임자 및 문의)
        </h2>
        <p className="text-gray-600 leading-relaxed">
          회사는 이용자의 개인정보 관련 문의사항을 처리하기 위해 개인정보 보호책임자를 지정하고
          있습니다.
        </p>
        <ul className="list-disc list-inside text-gray-600 mt-4">
          <li>
            <strong>개인정보 보호책임자:</strong> 유성봉
          </li>
          <li>
            <strong>연락처:</strong> support@castingvote.im
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Private;
