import axios, { AxiosError, AxiosResponse } from "axios";
import { Toast } from "hooks/useToast";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { ERROR } from "type/common";
import {
  SendProfileToStaffRequest,
  SendProfileToStaffResponse,
  ProfileQueryParams,
  ReceivedProfileDetails,
  ReadDeliveryProfileResponse,
  SendProfileToCastRequest,
  SendProfileToCastResponse,
  DeliveryProfileDetailResponse,
} from "type/deliveryProfile";
import requestToCastingVote from "utils/requestToCastingVote";

// 스탭에게 프로필 보내기
const sendProfileToStaff = (
  profileData: SendProfileToStaffRequest
): Promise<AxiosResponse<SendProfileToStaffResponse>> => {
  return requestToCastingVote.post<SendProfileToStaffResponse>(
    "/delivery-profiles",
    profileData
  );
};
export const useSendProfileToStaff = () => {
  return useMutation(
    (profileData: SendProfileToStaffRequest) => sendProfileToStaff(profileData),
    {
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 공고에 프로필 보내기
const sendProfileToCasting = (
  profileData: SendProfileToCastRequest
): Promise<SendProfileToCastResponse> => {
  return requestToCastingVote.post("/delivery-profiles/cast", profileData);
};
export const useSendProfileToCasting = () => {
  return useMutation<SendProfileToCastResponse, AxiosError<ERROR>, SendProfileToCastRequest>(
    sendProfileToCasting,
    {
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message || "Failed to send profile to casting");
      },
    }
  );
};

// 보낸 프로필 목록 조회
// 무한스크롤
const fetchSendProfiles = (params: ProfileQueryParams) => {
  return requestToCastingVote({
    method: "GET",
    url: "/delivery-profiles/sent",
    params,
  }).then((res) => res.data);
};
export const useSendProfiles = (params: ProfileQueryParams) => {
  return useInfiniteQuery(
    ["sendProfiles", params],
    ({ pageParam = 0 }) =>
      fetchSendProfiles({ ...params, page: pageParam, size: 20 }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      cacheTime: 0,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 보낸 프로필 상세 조회
const fetchDeliveryProfileDetail = async (
  id: number
): Promise<AxiosResponse<DeliveryProfileDetailResponse>> => {
  return requestToCastingVote.get(`delivery-profiles/${id}/sent`);
};
export const useDeliveryProfileDetail = (id: number) => {
  return useQuery(
    ["deliveryProfile", id],
    () => fetchDeliveryProfileDetail(id),
    {
      enabled: !!id,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 받은 프로필 목록 조회
// 무한스크롤
const fetchReceivedProfiles = ({
  page,
  size,
  castingCallId,
}: {
  page: number;
  size?: number;
  castingCallId?: number;
}) => {
  return requestToCastingVote({
    method: "GET",
    url: `/delivery-profiles/received`,
    params: { page, size, castingCallId },
  }).then((res) => res.data);
};

export const useReceivedProfiles = (castingCallId?: number) => {
  return useInfiniteQuery(
    ["receivedProfile", castingCallId],
    ({ pageParam = 0 }) =>
      fetchReceivedProfiles({ page: pageParam, castingCallId, size: 24 }),
    {
      getNextPageParam: (lastPage, allPosts) => {
        return lastPage?.data.last
          ? undefined
          // ?? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 받은 프로필 상세 조회
const fetchReceivedProfileDetails = (id: number) => {
  return requestToCastingVote.get<AxiosResponse<ReceivedProfileDetails>>(
    `/delivery-profiles/${id}/received `
  );
};
export const useReceivedProfileDetails = (id: number) => {
  return useQuery(
    ["receivedProfile", id],
    () => fetchReceivedProfileDetails(id),
    {
      enabled: !!id,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 받은 프로필 읽기
const readDeliveryProfile = (id: number) => {
  return requestToCastingVote.patch<ReadDeliveryProfileResponse>(
    `http://api.castingvote.io:8443/delivery-profiles/${id}/read`
  );
};
export const useReadDeliveryProfile = () => {
  return useMutation(readDeliveryProfile);
};

//   받은 프로필 피드백
const submitFeedback = async (id: number, rating: number, comments: string) => {
  return requestToCastingVote.patch(`/delivery-profiles/${id}/feedback`, {
    rating,
    comments,
  });
};
export const useSubmitFeedback = () => {
  return useMutation(
    (feedbackData: { id: number; rating: number; comments: string }) =>
      submitFeedback(
        feedbackData.id,
        feedbackData.rating,
        feedbackData.comments
      ),
    {
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};
