import React, { useEffect, useRef, useState } from "react";
import ContentWrap from "components/ContentWrap";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import { ReactComponent as Share } from "../assets/share.svg";
import Button from "components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authInfo, info } from "store/auth/atom";
import { useCastingCallDetail } from "api/CastingCall/CastingCall";
import BottomPopup from "components/BottomPopup";
import { useSendProfileToCasting } from "api/DeliveryProfile/DeliveryProfile";
import { Toast } from "hooks/useToast";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import ButtonBackground from "components/ButtonBackground";
import ScrollToTop from "components/ScrollToTop";

const CastingDetail = () => {
  const navigate = useNavigate();
  const [sendProfile, setSendProfile] = useState(false);
  const [auth, setAuth] = useRecoilState(authInfo);
  const [sendProfileData, setSendProfileData] = useState<{
    staffId: null | number;
    profileId: null | number;
    castingCallId: null | number;
  }>({
    staffId: null,
    profileId: Number(auth?.profileId),
    castingCallId: null,
  });
  const [userData, setUserData] = useRecoilState<{
    id: string;
    username: string;
    role: string;
  }>(info);

  const { itemId } = useParams();

  const { data, isLoading } = useCastingCallDetail(Number(itemId));

  const CastingDetail = data?.data.data;

  const deadline =
    CastingDetail?.deadlineDate &&
    `${CastingDetail?.deadlineDate?.split("-")[1]}월 ${
      CastingDetail?.deadlineDate?.split("-")[2]
    }일`;

  const castingInfo = [
    {
      id: 0,
      Title: "작품제목",
      Desc: CastingDetail?.productionTitle,
    },
    {
      id: 1,
      Title: "분야",
      Desc: CastingDetail?.productionFormat?.label,
    },
    {
      id: 2,
      Title: "제작사",
      Desc: CastingDetail?.companyName,
    },
    {
      id: 3,
      Title: "감독",
      Desc: CastingDetail?.directorName,
    },
    {
      id: 4,
      Title: "배역",
      Desc: CastingDetail?.shootingDetails.role.label,
    },
    {
      id: 5,
      Title: "성별",
      Desc: CastingDetail?.shootingDetails.gender.label,
    },
    {
      id: 6,
      Title: "나이",
      Desc: `${CastingDetail?.shootingDetails.minAge}세 ~ ${CastingDetail?.shootingDetails.maxAge}세`,
    },
    {
      id: 7,
      Title: "출연료",
      Desc: CastingDetail?.shootingDetails.fee,
    },
    {
      id: 8,
      Title: "마감일자",
      Desc: deadline,
    },
    {
      id: 9,
      Title: "캐스팅",
      Desc: CastingDetail?.castingDirectorName,
    },
  ];

  const castingDesc = [
    {
      id: 0,
      title: "상세내용",
      desc: CastingDetail?.description,
    },
  ];

  const mutate = useSendProfileToCasting();

  const profileSend = () => {
    mutate.mutate(
      {
        ...sendProfileData,
      },
      {
        onSuccess: () => {
          Toast.success("프로필을 보냈어요.");
          setSendProfile(false);
        },
      }
    );
  };

  if (isLoading) return <div></div>;

  return (
    <>
      <HeaderWithBackButton title={""}>
        {/* <Bookmark /> */}
      </HeaderWithBackButton>
      <div className="pb-24 px-5">
        <ScrollToTop />

        {sendProfile && (
          <BottomPopup onClose={() => setSendProfile(false)}>
            <div className="flex flex-col gap-4">
              <div className="BBody20">
                👀 프로필 보내기 전에
                <br />
                아래 사항을 꼭 확인해주세요
              </div>
              <div className="w-full h-[176px] p-4 rounded-[10px] overflow-scroll bg-Gray01 text-Gray05">
                <ol className="list-disc list-inside MBody16">
                  <li>
                    {" "}
                    스탭 회원의 캐스팅 공고와 제안은 캐스팅보트가 책임지지
                    않으니 승인되지 않은 스탭의 허위 공고 또는 제안을
                    조심하세요.
                  </li>
                  <li>
                    보내는 프로필에 연기영상/자유영상을 첨부하면 캐스팅 확률이
                    높아져요!
                  </li>
                  <li>프로필 받은 스탭은 내 연락처를 열람할 수 있어요.</li>
                </ol>
              </div>
              <ButtonBackground>
                <Button
                  onClick={() => {
                    profileSend();
                  }}
                  className="bg-Blue04 w-full py-3 MBody16 text-white border-none"
                  text={"확인했어요"}
                ></Button>
              </ButtonBackground>
            </div>
          </BottomPopup>
        )}
        <div>
          <div className="BBody20">{CastingDetail?.title}</div>
          {CastingDetail?.attachments.map((item, i) => {
            if (item.type.code === "THUMBNAIL")
              return (
                <div key={item.id} className="mt-4">
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                    className="w-full h-[360px] object-cover rounded-[10px]"
                    alt="thumbnail"
                  />
                </div>
              );
          })}
          <div className="flex mt-4 flex-col mb-10 items-start gap-4">
            <div className="w-full">
              <div>
                <div className="bg-[#F9FAFA] w-full rounded-[10px] p-4 flex flex-col gap-2">
                  {castingInfo.map((item) => {
                    if (item.Desc)
                      return (
                        <div key={item.id}>
                          <div className="flex gap-[39px] items-start BBody14">
                            <div className="flex items-center w-[75px] text-Gray05">
                              {item.Title}
                            </div>
                            <div className="text-Gray09 flex-1">
                              {item.Desc}
                            </div>
                          </div>
                        </div>
                      );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-10 w-full">
            {castingDesc.map((item) => {
              if (item.desc)
                return (
                  <div key={item.id}>
                    <div className="BBody18">{item.title}</div>
                    <div className="RBody16 mt-4 whitespace-pre-wrap">
                      {item.desc}
                    </div>
                  </div>
                );
            })}
            <div>
              {CastingDetail?.attachments.some(
                (attachment) => attachment.type.code === "FILE"
              ) && (
                <>
                  <div className="BBody18">첨부파일</div>
                  <div className="RBody16 mt-4">
                    {CastingDetail?.attachments.map((attItem, i) => {
                      if (attItem.type.code === "FILE")
                        return (
                          <div
                            className="cursor-pointer inline-block"
                            onClick={() => {
                              window.open(
                                `${process.env.REACT_APP_MEDIA_URL}${attItem?.url}`,
                                "_blank",
                                "noopener, noreferrer"
                              );
                            }}
                            key={attItem.id}
                          >
                            {attItem?.originalFilename}
                          </div>
                        );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* MANAGER, CASTING_DIRECTOR, DIRECTOR, PRODUCER */}

        {userData?.role === "ARTIST" && (
          <ButtonBackground>
            <Button
              onClick={() => {
                if (auth.profileId === null) {
                  navigate("/artist/my");
                } else {
                  setSendProfileData({
                    ...sendProfileData,
                    staffId: Number(CastingDetail?.castingDirectorId),
                    castingCallId: Number(CastingDetail?.castingCallId),
                  });
                  setSendProfile(true);
                }
              }}
              className="bg-Blue04 w-full py-3 MBody16 text-white border-none"
              text={"프로필 보내기"}
            ></Button>
          </ButtonBackground>
        )}
      </div>
    </>
  );
};

export default CastingDetail;
