import { useRecoilValue } from "recoil";
import { authInfo } from "store/auth/atom";

// const ROLE = [
//     {id:0, role: "ROLE_ARTIST", title:"아티스트"},
//     {id:1, role: "ROLE_MANAGER", title:"매니저"},
//     {id:2, role: "ROLE_CASTING_DIRECTOR", title:"캐스팅디렉터"},
//     {id:3, role: "ROLE_DIRECTOR", title:"감독"},
//     {id:4, role: "ROLE_PRODUCER", title:"프로듀서"},
//     {id:5, role: "ROLE_AGENCY", title:"감독"},
//     {id:6, role: "ROLE_WRITER", title:"작가"}
//   ]
//   const APPOVAL = [
//     {id:0, role: "AUTH_MEMBER_PENDING", title:"승인 대기"},
//     {id:1, role: "AUTH_MEMBER_APPROVED", title:"승인"},
//     {id:2, role: "AUTH_MEMBER_REJECTED", title:"승인 거절"},
//     {id:3, role: "AUTH_MEMBER_SUSPENDED", title:"정지"},
//     {id:4, role: "AUTH_MEMBER_DELETED", title:"탈퇴"}
//   ]
//   const STATUS = [
//     {id:0, role: "AUTH_MEMBERSHIP_DEFAULT", title:"기본"},
//     {id:1, role: "AUTH_MEMBERSHIP_ACTIVE", title:"활성"},
//     {id:2, role: "AUTH_MEMBERSHIP_INACTIVE", title:"비활성"},
//     {id:3, role: "AUTH_MEMBERSHIP_CANCEL_REQUEST", title:"취소 요청"},
//     {id:4, role: "AUTH_MEMBERSHIP_CANCELED", title:"취소됨"},
//     {id:5, role: "AUTH_MEMBERSHIP_EXPIRED", title:"만료됨"},
//     {id:6, role: "AUTH_MEMBERSHIP_SUSPENDED", title:"정지"}
//   ]

export const MEMBERSHIP_ACTIVE = () => {
    const names = useRecoilValue(authInfo)?.authorities;
    return names?.includes("AUTH_MEMBERSHIP_ACTIVE") || false;
  };
export const MEMBER_APPROVED = () => {
    const names = useRecoilValue(authInfo)?.authorities;
    return names?.includes("AUTH_MEMBER_APPROVED") || false;
  };
