import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as Filter } from "assets/icon_filter.svg";
import ContentWrap from "components/ContentWrap";
import NoticeCard from "components/NoticeCard";
import SearchBox from "components/SearchBox";
import { useNavigate } from "react-router-dom";
import Chip from "components/Chip";
import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import TitleWrap from "components/TitleWrap";
import Button from "components/Button";
import SideTab from "components/SideTab";
import { useCastingCalls } from "api/CastingCall/CastingCall";
import { innderHeightValue } from "store/auth/atom";
import { useRecoilState } from "recoil";
import { CASTING_LIST, GENDER_LIST, TYPE_LIST } from "utils/type";
import Skeleton from "components/Skeleton";
import { useInView } from "react-intersection-observer";
import useScrollRestoration from "hooks/useScrollRestoration";
import Header from "components/Header";
import { Virtuoso } from "react-virtuoso";
import Loading from "components/Loading";
import ReactVirtuoso from "components/ReactVirtuoso";

const CastingList = () => {
  const navigate = useNavigate();
  const { scrollRef } = useScrollRestoration();
  type SearchType = "title" | "prodTitle" | "comName" | "director";

  const [searchValues, setSearchValues] = useState<Record<SearchType, string>>({
    title: "",
    prodTitle: "",
    comName: "",
    director: "",
  });
  const [sort, setSort] = useState("createdAt,desc");
  const [selectedType, setSelectedType] = useState<SearchType>("title");
  const [innderHeight, setInnderHeight] =
    useRecoilState<number>(innderHeightValue);

  const [format, setFormat] = useState("");
  const [role, setRole] = useState("");
  const [gender, setGender] = useState("");
  const [search, setSearch] = useState<string>("");
  const [debouncedSearchValues, setDebouncedSearchValues] =
    useState(searchValues);
  const [isSideTabOpen, setIsSideTabOpen] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSearchValues({
      title: "",
      prodTitle: "",
      comName: "",
      director: "",
    });

    setSelectedType(value as SearchType);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValues(searchValues);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValues]);

  const [filters, setFilters] = useState({
    type: "",
    format: "",
    deadlineType: "",
    role: "",
    ageType: "",
    gender: "",
  });

  const handleOpenTab = () => setIsSideTabOpen(true);
  const handleCloseTab = () => setIsSideTabOpen(false);

  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useCastingCalls({
      sort: sort,
      title: debouncedSearchValues.title,
      productionTitle: debouncedSearchValues.prodTitle,
      companyName: debouncedSearchValues.comName,
      directorName: debouncedSearchValues.director,
      ...filters,
    });

  const options = [
    { id: 0, title: "제목", value: "title" },
    { id: 1, title: "작품", value: "prodTitle" },
    { id: 2, title: "제작사", value: "comName" },
    { id: 3, title: "감독", value: "director" },
  ];

  const allLists = useMemo(
    () => (data ? data?.pages?.flatMap((page) => page.data.content) : []),
    [data]
  );

  const loadMore = useCallback(() => {
    return setTimeout(() => {
      fetchNextPage();
    }, 500); // 디바운스
  }, []);

  return (
    <>
      <Header title="캐스팅" />
      <div className="pb-[77px] px-5 mt-2">
        <SideTab isOpen={isSideTabOpen} onClose={handleCloseTab}>
          <div className="pl-2 p-4 flex items-center justify-between">
            <div
              onClick={handleCloseTab}
              className="MBody18 cursor-pointer flex items-center"
            >
              <ArrowLeft />
              <div>필터</div>
            </div>
            <div
              onClick={() => {
                setGender("");
                setFormat("");
                setRole("");
              }}
              className="BBody18 cursor-pointer text-Blue04"
            >
              초기화
            </div>
          </div>
          <div
            style={{ height: `${innderHeight - 127}px` }}
            className="pt-6 pl-4 pb-[100px] flex flex-col items-start gap-10 overflow-y-auto"
          >
            <TitleWrap title="분야">
              <div className="flex items-center flex-wrap gap-2">
                {TYPE_LIST.map((item, i) => {
                  return (
                    <Chip
                      selected={item.role === format}
                      onClick={() => {
                        if (format === item.role) {
                          setFormat("");
                        } else {
                          setFormat(item.role);
                        }
                      }}
                      key={i}
                      title={item.title}
                    ></Chip>
                  );
                })}
              </div>
            </TitleWrap>
            <TitleWrap title="배역">
              <div className="flex items-center flex-wrap gap-2">
                {CASTING_LIST.map((item, i) => {
                  return (
                    <Chip
                      selected={item.role === role}
                      onClick={() => {
                        if (role === item.role) {
                          setRole("");
                        } else {
                          setRole(item.role);
                        }
                      }}
                      key={i}
                      title={item.title}
                    ></Chip>
                  );
                })}
              </div>
            </TitleWrap>
            <TitleWrap title="성별">
              <div className="flex items-center flex-wrap gap-2">
                {GENDER_LIST.map((item, i) => {
                  return (
                    <Chip
                      selected={item.role === gender}
                      onClick={() => {
                        if (gender === item.role) {
                          setGender("");
                        } else {
                          setGender(item.role);
                        }
                      }}
                      key={i}
                      title={item.title}
                    ></Chip>
                  );
                })}
              </div>
            </TitleWrap>
          </div>
          <div className="px-4">
            <Button
              onClick={() => {
                setFilters({
                  ...filters,
                  format: format,
                  gender: gender,
                  role: role,
                });
                handleCloseTab();
              }}
              className="w-full border-none bg-Blue04 text-white"
              text={"필터 적용하기"}
            ></Button>
          </div>
        </SideTab>
        <div className="flex items-center gap-4">
          <SearchBox
            onDropDownChange={onTypeChange}
            onChange={(e) => {
              const value = e.target.value;
              setSearchValues({
                ...searchValues,
                [selectedType]: value,
              });
            }}
            value={searchValues[selectedType]}
            placeholder="작품 또는 캐스팅 공고를 입력해 주세요"
          >
            {options.map((item) => {
              return (
                <option key={item.id} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </SearchBox>
        </div>
        <div className="flex items-center my-4 justify-between">
          <select
            onChange={(e) => {
              setSort(e.target.value);
            }}
          >
            <option value="createdAt,desc">등록순</option>
            <option value="updatedAt,desc">최신순</option>
          </select>
          <Filter className="cursor-pointer" onClick={handleOpenTab} />
        </div>
        {data?.pages[0].data.content.length === 0 ? (
          <div className="mt-36 items-center text-center">
            <div className="text-Gray05 RBody14">모집중인 캐스팅이 없어요.</div>
          </div>
        ) : (
          <div>
            {isLoading ? (
              Array.from({ length: 5 }).map((_, index) => (
                <Skeleton key={index} type="PROFILE_LIST" />
              ))
            ) : (
              <ReactVirtuoso
                isLoading={isLoading}
                useWindowScroll
                data={isLoading ? Array.from({ length: 1 }) : allLists}
                overscan={100}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                fetchNextPage={() => loadMore()}
                itemContent={(index, item) => {
                  const now = new Date();
                  const deadlineDate = new Date(item?.deadlineDate);

                  const timeDifference = Math.abs(
                    deadlineDate.getTime() - now.getTime()
                  );
                  const dayDifference = Math.ceil(
                    timeDifference / (1000 * 60 * 60 * 24)
                  );

                  return (
                    <div className={`${index !== 0 && "pt-4"}`}>
                      <NoticeCard
                        key={item.castingCallId}
                        title={item.title}
                        productionTitle={item.productionTitle}
                        src={item.thumbnailUrl}
                        type={item?.productionFormat?.label}
                        gender={item.gender.label}
                        age={`${item.minAge}세 ~ ${item.maxAge}세`}
                        deadlineSoon={
                          dayDifference < 2 && dayDifference > 0 ? true : false
                        }
                        deadline={
                          item?.deadlineDate !== null
                            ? `${item?.deadlineDate}까지`
                            : "캐스팅 확정 시 마감"
                        }
                        onClick={() =>
                          navigate(`/casting/${item.castingCallId}`)
                        }
                      />
                    </div>
                  );
                }}
              />
            )}
            {/* <div className="flex flex-col gap-4">
            {isLoading
              ? Array.from({ length: 1 }).map((_, index) => (
                  <Skeleton key={index} type="PROFILE_LIST" />
                ))
              : data?.pages?.map((item) => {
                  return item.data.content.map((staffItem: any) => {
                    const now = new Date();
                    const deadlineDate = new Date(staffItem?.deadlineDate);

                    const timeDifference = Math.abs(
                      deadlineDate.getTime() - now.getTime()
                    );
                    const dayDifference = Math.ceil(
                      timeDifference / (1000 * 60 * 60 * 24)
                    );

                    return (
                      <NoticeCard
                        key={staffItem.castingCallId}
                        title={staffItem.title}
                        productionTitle={staffItem.productionTitle}
                        src={staffItem.thumbnailUrl}
                        type={staffItem?.productionFormat?.label}
                        gender={staffItem.gender.label}
                        age={`${staffItem.minAge}세 ~ ${staffItem.maxAge}세`}
                        deadlineSoon={
                          dayDifference < 2 && dayDifference > 0 ? true : false
                        }
                        deadline={
                          staffItem?.deadlineDate !== null
                            ? `${staffItem?.deadlineDate}까지`
                            : "캐스팅 확정 시 마감"
                        }
                        onClick={() => {
                          navigate(`/casting/${staffItem.castingCallId}`);
                        }}
                      />
                    );
                  });
                })}
              <div ref={ref} />
          </div> */}
          </div>
        )}
      </div>
    </>
  );
};

export default CastingList;
