import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Outlet, ScrollRestoration } from "react-router-dom";
import TabBar from "./TabBar";
import requestToCastingVote from "utils/requestToCastingVote";
import Loading from "./Loading";

const Layout = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    requestToCastingVote.interceptors.request.use(
      (config) => {
        setLoading(true);
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    requestToCastingVote.interceptors.response.use(
      (response) => {
        setLoading(false);
        return response;
      },
      (error) => {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <>
      {/* {loading && <Loading />} */}
      <div className="w-full min-h-full bg-Gray00 shadow-[0_0px_20px_rgba(0,0,0,0.1)] relative min-w-[280px] max-w-[400px] mx-auto">
        <Outlet />
        <TabBar />
        <Footer />
      </div>
    </>
  );
};

export default Layout;
