import { useMyCastingCallDetail } from "api/Mypage/Mypage";
import ContentWrap from "components/ContentWrap";
import React from "react";
import { useParams } from "react-router-dom";
import CastingDetail from "./CastingDetail";
import HeaderWithBackButton from "components/HeaderWithBackButton";

const MyCasting = () => {
  const { itemId } = useParams();
  const { data: productCastingCall } = useMyCastingCallDetail(Number(itemId));

  const CastingDetail = productCastingCall?.data.data;

  const deadline =
    CastingDetail?.deadlineDate &&
    `${CastingDetail?.deadlineDate?.split("-")[1]}월 ${
      CastingDetail?.deadlineDate?.split("-")[2]
    }일`;

  const castingInfo = [
    {
      id: 0,
      Title: "분야",
      Desc: CastingDetail?.productionFormat?.label,
    },
    {
      id: 1,
      Title: "제작사",
      Desc: CastingDetail?.companyName,
    },
    {
      id: 2,
      Title: "감독",
      Desc: CastingDetail?.directorName,
    },
    {
      id: 3,
      Title: "배역",
      Desc: CastingDetail?.shootingDetails.role.label,
    },
    {
      id: 4,
      Title: "성별",
      Desc: CastingDetail?.shootingDetails.gender.label,
    },
    {
      id: 5,
      Title: "나이",
      Desc: `${CastingDetail?.shootingDetails.minAge}세 ~ ${CastingDetail?.shootingDetails.maxAge}세`,
    },
    {
      id: 6,
      Title: "출연료",
      Desc: CastingDetail?.shootingDetails.fee,
    },
    {
      id: 7,
      Title: "마감일자",
      Desc: deadline,
    },
    {
      id: 8,
      Title: "캐스팅",
      Desc: CastingDetail?.castingDirectorName,
    },
  ];

  const castingDesc = [
    {
      id: 0,
      title: "상세내용",
      desc: CastingDetail?.description,
    },
  ];

  return (
    <>
      <HeaderWithBackButton title={""} />
    <div className="px-5 pt-5 pb-24">
      <div>
        <div className="flex justify-between items-center MBody16">
          <div className="BBody20">{CastingDetail?.title}</div>
        </div>
        {CastingDetail?.productionTitle && (
          <div
            onClick={() => {
              //   navigate(`/staff/filmo/${CastingDetail?.productionId}`);
            }}
            className="BBody16 cursor-pointer mt-2 inline-block"
          >
            {CastingDetail?.productionTitle}
          </div>
        )}
        {CastingDetail?.attachments.map((item, i) => {
          if (item.type.code === "THUMBNAIL")
            return (
              <div className="mt-4">
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                  className="w-full h-[360px] object-cover rounded-[10px]"
                  alt="thumbnail"
                />
              </div>
            );
        })}
        <div className="flex mt-4 flex-col mb-10 items-start gap-4">
          <div className="w-full">
            <div>
              <div className="bg-[#F9FAFA] w-full rounded-[10px] p-4 flex flex-col gap-2">
                {castingInfo.map((item) => {
                  if (item.Desc)
                    return (
                      <div key={item.id}>
                        <div className="flex gap-[39px] items-start BBody14">
                          <div className="flex items-center w-[75px] text-Gray05">
                            {item.Title}
                          </div>
                          <div className="text-Gray09 flex-1">{item.Desc}</div>
                        </div>
                      </div>
                    );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-10 w-full">
          {castingDesc.map((item) => {
            if (item.desc)
              return (
                <div key={item.id}>
                  <div className="BBody18">{item.title}</div>
                  <div className="RBody16 mt-4 whitespace-pre-wrap">
                    {item.desc}
                  </div>
                </div>
              );
          })}
          <div>
            {CastingDetail?.attachments.some(
              (attachment) => attachment.type.code === "FILE"
            ) && (
              <>
                <div className="BBody18">첨부파일</div>
                <div className="RBody16 mt-4">
                  {CastingDetail?.attachments.map((attItem, i) => {
                    if (attItem.type.code === "FILE")
                      return (
                        <div
                          className="cursor-pointer inline-block"
                          onClick={() => {
                            window.open(
                              `${process.env.REACT_APP_MEDIA_URL}${attItem?.url}`,
                              "_blank",
                              "noopener, noreferrer"
                            );
                          }}
                          key={attItem.id}
                        >
                          {attItem?.originalFilename}
                        </div>
                      );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
    </>

  );
};

export default MyCasting;
