import { AxiosError, AxiosResponse } from "axios";
import { Toast } from "hooks/useToast";
import { useInfiniteQuery, useQuery, UseQueryResult } from "react-query";
import {
  APIResponse,
  CastingCallDetailResponse,
} from "type/artist";
import { ERROR } from "type/common";
import { FetchCastingCallsParams } from "type/myPage";
import {
  CastingCallsResponse,
  myCastingCallByProduct,
  MyPageData,
  MyProductionDetail,
  MyProductions,
  mySkateholders,
} from "type/staff";
import requestToCastingVote from "utils/requestToCastingVote";

// 내 정보
const fetchMyInfo = async () => {
  return requestToCastingVote({
    method: "GET",
    url: "mypage/info",
  });
};

export const useMyInfo = () => {
  return useQuery("myInfo", fetchMyInfo, {
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
};

// 내 프로필
const getMyPage = () => {
  return requestToCastingVote({
    method: "GET",
    url: "/mypage/profile",
  });
};
export const useMyPage = (): UseQueryResult<
  AxiosResponse<MyPageData>,
  AxiosError<ERROR>
> =>
  useQuery(["useMyPage"], () => getMyPage(), {
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });

// 스태프 내 작품 목록
const fetchProductionList = ({ status, page, size }: MyProductions) => {
  return requestToCastingVote({
    method: "GET",
    url: "/mypage/productions",
    params: {
      status,
      page,
      size,
    },
  }).then((res) => res.data);
};
export const useProductions = (params?: MyProductions) => {
  return useInfiniteQuery(
    ["myProductions", params],
    ({ pageParam = 0 }) =>
      fetchProductionList({ ...params, page: pageParam, size: 20 }),
    {
      getNextPageParam: (lastPage, allPosts) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 스태프 내 작품 상세
const fetchProductionDetail = (
  id: number
): Promise<AxiosResponse<MyProductionDetail>> => {
  return requestToCastingVote.get(`/mypage/productions/${id}`);
};

export const useProductionDetailQuery = (
  id: number
): UseQueryResult<AxiosResponse<MyProductionDetail>, AxiosError> => {
  return useQuery(["productionDetail", id], () => fetchProductionDetail(id), {
    enabled: !!id, // id가 있을 때만 요청 수행
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
};

// 스태프 내 공고 목록
const fetchMyCastingCalls = (param: FetchCastingCallsParams) => {
  return requestToCastingVote({
    method: "GET",
    url: "/mypage/castingcalls",
    params: param,
  }).then((res) => res.data);
};

export const useMyCastingCalls = (param: FetchCastingCallsParams) => {
  return useInfiniteQuery(
    ["myCastingCalls", param],
    ({ pageParam = 0 }) =>
      fetchMyCastingCalls({ page: pageParam, ...param, size: 24 }),
    {
      getNextPageParam: (lastPage, allPosts) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 스태프 내 공고 상세
const fetchMyCastingCallDetail = (id: number) => {
  return requestToCastingVote.get<CastingCallDetailResponse>(
    `mypage/castingcalls/${id}`
  );
};
export const useMyCastingCallDetail = (
  id: number
): UseQueryResult<
  AxiosResponse<APIResponse<CastingCallDetailResponse>>,
  Error
> => {
  return useQuery(
    ["castingCallDetail", id],
    () => fetchMyCastingCallDetail(id),
    {
      enabled: !!id,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 스태프 내 작품별 공고 목록
const fetchMyProductionCastingCalls = (params: myCastingCallByProduct) => {
  return requestToCastingVote({
    method: "GET",
    url: `mypage/productions/${params.id}/castingcalls`,
    params,
  }).then((res) => res.data);
};

export const useMyProductionCastingCalls = (params: myCastingCallByProduct) => {
  return useInfiniteQuery(
    ["myCastingCallByProduct", params],
    ({ pageParam = 0 }) =>
      fetchMyProductionCastingCalls({ page: pageParam, size: 8, ...params }),
    {
      getNextPageParam: (lastPage, allPosts) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
      enabled: !!params?.id,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 스태프 내 관계자로 추가된 공고 목록
const fetchMySkateholders = (params: mySkateholders) => {
  return requestToCastingVote({
    method: "GET",
    url: "mypage/castingcalls/stakeholder",
    params,
  }).then((res) => res.data);
};

export const useMySkateholders = (params?: mySkateholders) => {
  return useInfiniteQuery(
    ["mySkateholders", params],
    ({ pageParam = 0 }) =>
      fetchMySkateholders({ page: pageParam, size: 20, ...params }),
    {
      getNextPageParam: (lastPage, allPosts) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};