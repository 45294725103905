import { useMySkateholders } from "api/Mypage/Mypage";
import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import useScrollRestoration from "hooks/useScrollRestoration";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

const CastingReference = () => {
  const navigate = useNavigate();
  const { scrollRef } = useScrollRestoration();
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const { data, fetchNextPage, hasNextPage } = useMySkateholders();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <>
      <HeaderWithBackButton title={"참여 캐스팅"} />
      <div className="pb-24 px-5">
        {data?.pages[0].data.content.length === 0 ? (
          <div className="mt-36 items-center text-center">
            <div className="text-Gray05 RBody14">참여한 캐스팅이 없어요.</div>
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {data?.pages?.map((data) => {
              return data.data.content.map((item: any) => {
                return (
                  <div
                    onClick={() => {
                      navigate(`/staff/casting/process/${item.castingCallId}`, {
                        state: {
                          title: item.title,
                          castingCallId: item.castingCallId,
                        },
                      });
                    }}
                    className="cursor-pointer"
                    key={item.castingCallId}
                  >
                    <div className="MBody16 text-Gray09">{item?.title}</div>
                    <div className="MBody14 text-Gray03 mt-2">
                      {item?.deadlineDate}
                    </div>
                  </div>
                );
              });
            })}
            <div ref={ref} />
          </div>
        )}
      </div>
    </>
  );
};

export default CastingReference;
