import React from "react";

export const useTodayWithNextMonth = () => {
  const today = new Date();
  const nextMonth = new Date(today);

  nextMonth.setMonth(nextMonth.getMonth() + 1);

  const getLastDayOfMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const lastDayOfNextMonth = getLastDayOfMonth(
    nextMonth.getFullYear(),
    nextMonth.getMonth()
  );

  if (nextMonth.getDate() > lastDayOfNextMonth) {
    nextMonth.setDate(lastDayOfNextMonth);
  }

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}. ${month}. ${day}`;
  };

  return {
    today: formatDate(today),
    nextMonth: formatDate(nextMonth),
  };
};
