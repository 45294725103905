import ContentWrap from 'components/ContentWrap';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import React from 'react';

const ArtistPick = () => {
    return (
        <div className='py-24 px-5'>
            <HeaderWithBackButton title={'캐스팅보트 P!ck'}/>
        
        </div>
    );
};

export default ArtistPick;