import React, { useEffect, useRef } from "react";
import useOutSideClick from "hooks/onOutSideClick";
import { createPortal } from "react-dom";

import { ReactComponent as XButton } from "assets/xbutton.svg";

interface ModalType {
  className?: string;
  children?: React.ReactNode;
  title?: string;
  subTitle?: string;
  closeButton?: boolean;

  onClose?: () => void;
}

function ModalContainer({ children }: { children: React.ReactNode }) {
  return createPortal(<>{children}</>, document.getElementById("modal")!);
}

const Modal = ({
  className,
  children,
  title,
  closeButton,
  subTitle,
  onClose,
}: ModalType) => {
  const modalRef = useRef(null);

  const handleClose = () => {
    onClose?.();
  };

  useOutSideClick(modalRef, handleClose);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <ModalContainer>
      <div className="fixed w-full h-full top-0 left-0 bg-black/45 z-[9999]">
        <div
          ref={modalRef}
          className={`rounded-xl bg-white absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 ${className}`}
        >
          <div className="p-5 min-w-[230px] max-w-[310px]">
            <div className="flex text-Gray09 BBody18 items-center justify-between">
              <div>{title}</div>
              {closeButton && (
                <XButton className="cursor-pointer" onClick={onClose} />
              )}
            </div>
            {subTitle && (
              <div className="my-4 RCaption12 text-Gray05">{subTitle}</div>
            )}
            {children}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default Modal;
