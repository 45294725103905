import axios from "axios";
import Button from "components/Button";
import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import PointCard from "components/PointCard";
import React from "react";
import { useNavigate } from "react-router-dom";

const PointsDetails = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeaderWithBackButton title={"포인트"} />
      <div className="pb-25 pt-5 px-5">
        <div>
          <div className="MBody16 text-Gray05">내 포인트</div>
          <div className="flex mt-2 items-center justify-between">
            <div className="text-Blue04 BHead24">999,999원</div>
            <Button
              onClick={() => {
                navigate("/point/charge");
              }}
              className="px-4 bg-Blue04 text-Gray00 rounded-lg py-1.5"
              text={"충전"}
            />
          </div>
          <div className="mt-10">
            <div className="MBody16 text-Gray-05 mb-4">충전내역</div>
            <div className=" flex flex-col gap-4">
              <PointCard />
              <PointCard />
              <PointCard />
              <PointCard />
              <PointCard />
              <PointCard />
              <PointCard />
              <PointCard />
            </div>
          </div>
          <div className="mt-20 flex flex-col gap-4">
            <div className="MBody16 text-Gray-05 mb-4">사용내역</div>
            <div className=" flex flex-col gap-4">
              <PointCard />
              <PointCard />
              <PointCard />
              <PointCard />
              <PointCard />
              <PointCard />
              <PointCard />
              <PointCard />
              <PointCard />
              <PointCard />
              <PointCard />
            </div>
          </div>
          <div
            onClick={() => {
              axios
                .post("https://apitest.kiwoompay.co.kr/pay/link", {
                  data: {
                    TYPE: "W",
                    PAYMETHOD: "CARD",
                    CPID: "CTS10273",
                    ORDERNO: "1",
                    PRODUCTTYPE: "1",
                    AMOUNT: "1",
                    PRODUCTNAME: "1",
                    PRODUCTCODE: "1",
                    USERID: "1",
                  },
                })
                .then((res) => {});
            }}
          >
            결제테스트
          </div>
        </div>
      </div>
    </>
  );
};

export default PointsDetails;
