import React, { useState } from "react";
import Skeleton from "./Skeleton";
import { ReactComponent as Checked } from "assets/checkedProfile.svg";
import { ReactComponent as BookOn } from "assets/icon_bookmark_on.svg";
import { ReactComponent as BookOff } from "assets/icon_bookmark_off.svg";
import Chip from "./Chip";

interface ArtistProfileCardType {
  src?: string;
  title?: string;
  subTitle?: string;
  description?: string;

  selectMode?: boolean;
  selected?: boolean;
  isOffered?: boolean;
  onClick?: () => void;
}

const ArtistListCard = ({
  src,
  title,
  subTitle,
  description,
  selectMode,
  selected,
  isOffered,
  onClick,
}: ArtistProfileCardType) => {
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="relative">
      {isLoading && <Skeleton type="ARTIST_LIST_CARD" />}
      {MEDIA_URL && (
        <img
          onClick={onClick}
          onLoad={(e) => {
            setIsLoading(false);
          }}
          src={`${MEDIA_URL}${src}`}
          alt="thumnailUrl"
          className={`w-[114px] cursor-pointer relative object-cover h-[143px] rounded-md ${
            selectMode && selected ? "cursor-pointer" : ""
          }
          ${selectMode && selected ? "opacity-50" : ""}
        `}
        />
      )}
      {selectMode && selected && (
        <Checked className="absolute right-2 top-2 z-50" />
      )}
      {isOffered && (
        <Chip
          selected
          className="absolute bg-Blue01 h-[18px] top-[121px] left-1 !MCaption10 text-Blue04 border-none rounded-md"
          title={"제안"}
        />
      )}
      {/* {!selectMode &&
      <BookOff className="absolute top-[115px] right-0" />
      } */}
      <div className="flex items-center gap-0.5 mt-2">
        <div className="BBody16 text-Gray09 max-w-[81px] text-ellipsis whitespace-nowrap overflow-hidden">
          {title}
        </div>
        <div className="MBody14 text-Gray05">{subTitle}</div>
      </div>
      {description && <div className="RBody14 text-Gray05">{description}</div>}
    </div>
  );
};

export default ArtistListCard;
