import ButtonBackground from "components/ButtonBackground";
import { FillButton } from "components/FillButton";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { LineButton } from "components/LineButton";
import { useLocation, useNavigate } from "react-router";

const MemberFindId = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <div>
      <HeaderWithBackButton title={"아이디 찾기"} />
      <div className="px-5">
        <div className="py-10">
          <div className="text-Gray05 RBody16">
            인증한 휴대폰 번호로 가입된 아이디예요.
          </div>
        </div>
        <div className="w-full bg-Gray01 py-3 text-center text-Gray09 MBody16 rounded-md">
          {state?.length !== 0 ? state.join() : "가입된 계정이 없습니다."}
        </div>
      </div>
      <ButtonBackground>
        <LineButton
          className="w-full"
          variant="normal"
          size="L"
          onClick={() => {
            navigate("/find/verify", { state: "PASSWORD" });
          }}
          text="비밀번호 찾기"
        ></LineButton>
        <FillButton
          className="w-full"
          onClick={() => {
            navigate("/login");
          }}
          variant="Membership"
          size="L"
          text="로그인하기"
        ></FillButton>
      </ButtonBackground>
    </div>
  );
};

export default MemberFindId;
