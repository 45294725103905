import useOutSideClick from "hooks/onOutSideClick";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

interface BottomPopupProps {
  className?: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BottomPopupContainer({ children }: { children: React.ReactNode }) {
  return createPortal(<>{children}</>, document.getElementById("bottomPopup")!);
}

const BottomPopup = ({ children, onClose, className }: BottomPopupProps) => {
  const bottomPopupRef = useRef(null);

  const handleClose = () => {
    onClose?.();
  };

  useOutSideClick(bottomPopupRef, handleClose);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <BottomPopupContainer>
      <div className="fixed top-0 z-[51] left-0 w-full h-full mx-auto bg-[#000] opacity-40" />
      <div
        ref={bottomPopupRef}
        className={`fixed left-0 animate-bottom transition-all bg-Gray00 right-0 bottom-0 z-[100] w-full max-w-[400px] mx-auto rounded-t-[20px] max-h-full box-border pb-24 p-5 flex-col flex ${className}`}
      >
        <div
          // onClick={() => {
          //   setHeight((height) => !height);
          // }}
          className="absolute top-1.5 left-2/4 -translate-x-2/4 w-9 h-1.5 bg-Gray05 rounded-sm"
        />
        {children}
      </div>
    </BottomPopupContainer>
  );
};

export default BottomPopup;
