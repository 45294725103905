import { useEffect, useState } from "react";

export const useScroll = () => {
  const [state, setState] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    // Throttle을 적용한 scroll handler
    let throttleTimeout: NodeJS.Timeout | null = null;

    const onScroll = () => {
      if (!throttleTimeout) {
        throttleTimeout = setTimeout(() => {
          setState({ y: window.scrollY, x: window.scrollX });
          throttleTimeout = null;
        }, 500);
      }
    };

    window.addEventListener("scroll", onScroll);

    // Cleanup
    return () => {
      if (throttleTimeout) {
        clearTimeout(throttleTimeout);
      }
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return state;
};
