import React, { useState } from "react";

import Insta from "../assets/Instagram_black.png";
import Youtue from "../assets/YouTube_black.png";
import { useProfileShare } from "api/Share/Share";
import ContentWrap from "components/ContentWrap";
import { useSearchParams } from "react-router-dom";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import YouTube from "react-youtube";
import { Autoplay } from "swiper/modules";
import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import ImageModal from "components/ImageModal";
import Skeleton from "components/Skeleton";

const ArtistShared = () => {
  const [searchParams] = useSearchParams();
  const [imageDetail, setImageDetail] = useState(false);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  const opt = {
    height: "203",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };
  const value = searchParams.get("value");
  const { data: ArtistData, isLoading } = useProfileShare(value!);

  const handleOpenNewTab = (url: string) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  const calculateAge = () => {
    const currentYear = new Date().getFullYear();
    const calculatedAge =
      currentYear - Number(ArtistData?.birthDate.split("-")[0]);
    return calculatedAge;
  };

  const filteredAttachments = ArtistData?.attachments
    ?.filter(
      (attachment) =>
        attachment.type.code !== "THUMBNAIL" &&
        attachment.type.code !== "ADDITIONAL_VIDEO"
    )
    ?.sort((a, b) => {
      if (
        a.type.code === "ADDITIONAL_IMAGE" &&
        b.type.code !== "ADDITIONAL_IMAGE"
      ) {
        return -1;
      } else if (
        a.type.code !== "ADDITIONAL_IMAGE" &&
        b.type.code === "ADDITIONAL_IMAGE"
      ) {
        return 1;
      }
      return 0;
    });

  const groupedByYear = ArtistData?.filmographies?.reduce((acc: any, item) => {
    const year = item.releaseYear;
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(item);

    acc[year].sort((a: any, b: any) => a.orderNumber - b.orderNumber);

    return acc;
  }, {});

  const artistInfo = [
    { id: 0, type: "거주지", data: ArtistData?.basicInfo.address.sido },
    { id: 1, type: "학력", data: ArtistData?.basicInfo.education },
    { id: 2, type: "매니저/소속사", data: ArtistData?.basicInfo.agency },
  ];

  const filteredArtistInfo = artistInfo.filter(
    (item) => item.data != null && item.data !== ""
  );

  const filteredAtt = ArtistData?.attachments!.filter(
    (attachment) => attachment.type.code !== "ADDITIONAL_VIDEO"
  );

  const handlePrev = () => {
    swiper?.slidePrev();
  };
  const handleNext = () => {
    swiper?.slideNext();
  };
  const expiredAt = new Date(ArtistData?.expiredAt!);
  const formattedDate = `${expiredAt.getFullYear()}년${String(
    expiredAt.getMonth() + 1
  ).padStart(2, "0")}월${String(expiredAt.getDate()).padStart(
    2,
    "0"
  )}일 ${String(expiredAt.getHours()).padStart(2, "0")}시${String(
    expiredAt.getMinutes()
  ).padStart(2, "0")}분`;

  return (
    <>
      {isLoading ? (
        <Skeleton type="PROFILE_DETAIL" />
      ) : (
        <div className="px-5 pb-24">
          {imageDetail && (
            <ImageModal
              onClose={() => setImageDetail(false)}
              className="max-w-[360px] w-full"
            >
              <Swiper
                onSwiper={(e: SwiperClass) => {
                  setSwiper(e);
                }}
                slidesPerView={1}
                className="relative"
              >
                {filteredAtt?.map((item, index) => {
                  return (
                    <SwiperSlide key={index} className="h-fit relative">
                      {MEDIA_URL && (
                        <img
                          src={`${MEDIA_URL}${item.url}`}
                          alt="IMAGES"
                          className="rounded-[10px] w-full max-w-[360px] h-[514px] mx-auto object-contain"
                        />
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <XButton
                onClick={() => {
                  setImageDetail(false);
                }}
                className="absolute cursor-pointer top-4 right-4 z-50"
              />
              <ArrowLeft
                onClick={() => {
                  handlePrev();
                }}
                className="absolute top-2/4 left-0 z-50 cursor-pointer"
              />
              <ArrowLeft
                onClick={() => {
                  handleNext();
                }}
                className="absolute top-2/4 right-0 z-50 rotate-180 cursor-pointer"
              />
            </ImageModal>
          )}
          <div className="MCaption12 text-Blue04 py-4">
            공유된 링크는 30일 동안 유효해요 ({formattedDate}까지)
          </div>
          <div className="w-full flex flex-col gap-2">
            <div>
              <div className="BBody14 text-Gray05">
                {ArtistData?.basicInfo.job.label}
              </div>
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <div className="BBody20">{ArtistData?.profileName}</div>
                  {ArtistData?.snsInfo.instagram && (
                    <img
                      onClick={() => {
                        handleOpenNewTab(ArtistData?.snsInfo.instagram!);
                      }}
                      className="cursor-pointer"
                      src={Insta}
                      alt="instagram"
                    />
                  )}
                  {ArtistData?.snsInfo.youtube && (
                    <img
                      onClick={() => {
                        handleOpenNewTab(ArtistData?.snsInfo.youtube!);
                      }}
                      className="cursor-pointer"
                      src={Youtue}
                      alt="youtube"
                    />
                  )}
                </div>
              </div>
              <div className="flex mb-2 items-center RBody16 gap-2">
                <div>{`${
                  ArtistData?.birthDate.split("-")[0]
                }년생 (${calculateAge()}세)`}</div>
                <div>{`${ArtistData?.basicInfo.height}cm`}</div>
                <div>{`${ArtistData?.basicInfo.weight}kg`}</div>
              </div>
              <div className="flex flex-wrap gap-x-2 RBody16 text-Blue04">
                {ArtistData?.hashtags?.map((item) => {
                  return <div key={item.id}>#{item.name}</div>;
                })}
              </div>
            </div>
            <div>
              {MEDIA_URL && (
                <img
                  onClick={() => {
                    setImageDetail(true);
                  }}
                  src={`${MEDIA_URL}${ArtistData?.thumbnailUrl}`}
                  alt=""
                  className="w-full h-[450px] object-cover rounded-[10px]"
                />
              )}
              <div className="w-full mt-2">
                <Swiper
                  autoplay={{ delay: 2000, disableOnInteraction: false }}
                  loop={true}
                  modules={[Autoplay]}
                  spaceBetween={8}
                  slidesPerView={filteredAttachments?.length! < 4 ? 3 : "auto"}
                >
                  {filteredAttachments?.map((item, idx) => {
                    return (
                      <SwiperSlide
                        className={
                          filteredAttachments.length! < 4 ? "w-full" : "!w-fit"
                        }
                        onClick={() => {
                          setImageDetail(true);
                        }}
                        key={idx}
                      >
                        <div className="w-full">
                          {MEDIA_URL && (
                            <img
                              src={`${MEDIA_URL}${item.url}`}
                              alt="IMAGES"
                              className="w-[114px] h-[114px] rounded-[10px] object-cover"
                            />
                          )}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {ArtistData?.attachments?.map((item, idx) => {
                if (item.type.code === "ADDITIONAL_VIDEO") {
                  return (
                    <video
                      key={item.id}
                      controls
                      className="w-full h-[186px] rounded-[10px]"
                    >
                      {MEDIA_URL && (
                        <source src={`${MEDIA_URL}${item.url}`}></source>
                      )}
                    </video>
                  );
                }
              })}
              {ArtistData?.videoLinks?.map((item, idx) => {
                let link = "";

                if (item.link.startsWith("https://www.youtube.com")) {
                  link = item.link.split("watch?v=")[1];
                } else {
                  link = item.link.split("?")[0].split("/")[3];
                }
                return (
                  <YouTube
                    key={item.orderNumber}
                    className={"w-full"}
                    opts={opt}
                    iframeClassName="youtubeRounded"
                    videoId={link}
                  />
                );
              })}
            </div>
          </div>
          <div className="flex-1 w-full">
            {filteredArtistInfo.length !== 0 && (
              <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
            )}
            <div className="flex flex-col gap-y-10 items-start">
              {artistInfo.map((item) => {
                if (item?.data)
                  return (
                    <React.Fragment key={item.id}>
                      <div>
                        <div className="flex flex-col">
                          <div className="RBody14 text-Gray05">{item.type}</div>
                          <div className="MBody16">{item.data}</div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
              })}
              <div>
                {ArtistData?.hobbies.length !== 0 && (
                  <div className="flex flex-col w-[360px]">
                    <div className="RBody14 text-Gray05">특기</div>
                    <div className="MBody16 flex items-center flex-wrap">
                      {ArtistData?.hobbies.map((item, idx) => {
                        return (
                          <div key={item.id}>
                            {item.name}
                            {idx < ArtistData.hobbies.length - 1 ? "/" : ""}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {ArtistData?.introduce !== "" && (
              <>
                <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
                <div className="w-full flex flex-col gap-2">
                  <div className="RBody16 text-Gray05">자기소개</div>
                  <div className="MBody16 max-h-[336px] whitespace-pre-wrap overflow-y-scroll">
                    {ArtistData?.introduce}
                  </div>
                </div>
              </>
            )}
            {ArtistData?.filmographies?.length !== 0 && (
              <>
                <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
                <div className="w-full flex flex-col gap-2">
                  <div className="RBody16 text-Gray05">필모그래피</div>
                  {ArtistData ? (
                    <div className="flex flex-col gap-4">
                      {Object.keys(groupedByYear)
                        .sort((a, b) => Number(b) - Number(a))
                        ?.map((year) => (
                          <div className="flex items-start gap-4" key={year}>
                            <div className="RBody14 text-Gray09">{year}</div>
                            <div className="flex flex-col items-baseline gap-4">
                              {groupedByYear[year].map((item: any) => (
                                <div key={item.id}>
                                  <div className="RBody14 text-Gray05">
                                    {item?.type?.label}
                                  </div>
                                  <div className="BBody16 mt-2 mb-1">
                                    {item.title}
                                  </div>
                                  <div className="BBody14 flex gap-1 text-Gray05 items-center">
                                    <div>{item?.role?.label}</div>•
                                    <div>{item?.description}</div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ArtistShared;
