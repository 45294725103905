import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import { ReactComponent as Filter } from "assets/icon_filter.svg";
import { ArtistList as ArtistListType } from "type/artist";
import SearchBox from "components/SearchBox";
import { useNavigate } from "react-router-dom";
import ContentWrap from "components/ContentWrap";
import Chip from "components/Chip";
import SideTab from "components/SideTab";
import TitleWrap from "components/TitleWrap";
import Button from "components/Button";
import { useArtists } from "api/Profile/Profile";
import { innderHeightValue } from "store/auth/atom";
import { useRecoilState } from "recoil";
import { GENDER_LIST, HOBBY_LIST, JOB_LIST } from "utils/type";
import { useInView } from "react-intersection-observer";
import ArtistListCard from "components/ArtistListCard";
import { splitBirth } from "utils/onBirth";
import Header from "components/Header";
import { VirtuosoGrid } from "react-virtuoso";
import ReactVirtuosoGrid from "components/ReactVirtuosoGrid";

const ArtistList = () => {
  const navigate = useNavigate();
  const [isSideTabOpen, setIsSideTabOpen] = useState(false);
  const [sort, setSort] = useState("createdAt,desc");
  type SearchType = "name" | "filmoTitle" | "filmoRole" | "agency" | "edu";

  const [searchValues, setSearchValues] = useState<Record<SearchType, string>>({
    name: "",
    filmoTitle: "",
    filmoRole: "",
    agency: "",
    edu: "",
  });

  const [selectedType, setSelectedType] = useState<SearchType>("name");
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const [job, setJop] = useState("");
  const [gender, setGender] = useState("");
  const [hashtagIds, setHashtagIds] = useState<number[]>([]);
  const [hobbyIds, setHobbyIds] = useState<number[]>([]);
  const [innderHeight, setInnderHeight] =
    useRecoilState<number>(innderHeightValue);

  const [artistData, setArtistData] = useState<ArtistListType>({
    gender: "",
    job: "",
    ageGoe: "",
    ageLoe: "",
    heightGoe: "",
    heightLoe: "",
    weightGoe: "",
    weightLoe: "",
    hashtagIds: "",
    hobbyIds: "",
  });

  const handleOpenTab = () => setIsSideTabOpen(true);
  const handleCloseTab = () => setIsSideTabOpen(false);

  const [debouncedSearchValues, setDebouncedSearchValues] =
    useState(searchValues);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useArtists({
      name: debouncedSearchValues.name,
      filmographyTitle: debouncedSearchValues.filmoTitle,
      roleName: debouncedSearchValues.filmoRole,
      education: debouncedSearchValues.edu,
      agency: debouncedSearchValues.agency,
      sort: sort,
      ...artistData,
    });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValues(searchValues);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValues]);

  const loadMore = useCallback(() => {
    return setTimeout(() => {
      fetchNextPage();
    }, 500); // 디바운스
  }, []);

  const hobby: { id: number; title: string }[] = [
    { id: 15, title: "액션" },
    { id: 16, title: "승마" },
    { id: 17, title: "영어" },
    { id: 18, title: "태권도" },
    { id: 19, title: "중국어" },
    { id: 20, title: "검도" },
    { id: 21, title: "달리기" },
    { id: 22, title: "일본어" },
    { id: 23, title: "한국무용" },
    { id: 24, title: "발레" },
    { id: 25, title: "수영" },
    { id: 26, title: "불어" },
  ];

  const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSearchValues({
      name: "",
      filmoTitle: "",
      filmoRole: "",
      agency: "",
      edu: "",
    });

    setSelectedType(value as SearchType);
  };

  const options = [
    { id: 0, title: "이름", value: "name" },
    { id: 1, title: "작품", value: "filmoTitle" },
    { id: 2, title: "배역", value: "filmoRole" },
    { id: 3, title: "소속사", value: "agency" },
    { id: 4, title: "학력", value: "edu" },
  ];

  const allLists = useMemo(
    () => (data ? data?.pages?.flatMap((page) => page.data.content) : []),
    [data]
  );

  const groupData = (data: any) => {
    const grouped = [];
    for (let i = 0; i < data?.length; i += 3) {
      grouped.push(data.slice(i, i + 3));
    }
    return grouped;
  };

  const groupedData = groupData(allLists);

  return (
    <>
      <Header title="아티스트" />
      <div className="pb-24 px-5 mt-2">
        <SideTab isOpen={isSideTabOpen} onClose={handleCloseTab}>
          <div className="pl-2 p-4 flex items-center justify-between">
            <div
              onClick={handleCloseTab}
              className="MBody18 cursor-pointer flex items-center"
            >
              <ArrowLeft />
              <div></div>
            </div>
            <div
              onClick={() => {
                setArtistData({
                  ...artistData,
                  hashtagIds: "",
                  hobbyIds: "",
                  gender: "",
                  job: "",
                });
                setJop("");
                setGender("");
                setHashtagIds([]);
                setHobbyIds([]);
              }}
              className="BBody18 cursor-pointer text-Blue04"
            >
              초기화
            </div>
          </div>
          <div
            style={{ height: `${innderHeight - 127}px` }}
            className="pt-6 pl-4 pb-[100px] flex flex-col items-start gap-10 overflow-y-auto"
          >
            <TitleWrap title="성별">
              <div className="flex items-center flex-wrap gap-2">
                {GENDER_LIST.map((item, i) => {
                  return (
                    <Chip
                      selected={gender === item.role}
                      onClick={() => {
                        if (artistData.gender === item.role) {
                          setGender("");
                        } else {
                          setGender(item.role);
                        }
                      }}
                      key={item.id}
                      title={item.title}
                    ></Chip>
                  );
                })}
              </div>
            </TitleWrap>
            <TitleWrap title="직업">
              <div className="flex items-center flex-wrap gap-2">
                {JOB_LIST.map((item, i) => {
                  return (
                    <Chip
                      selected={job === item.role}
                      onClick={() => {
                        if (artistData.job === item.role) {
                          setJop("");
                        } else {
                          setJop(item.role);
                        }
                      }}
                      key={item.id}
                      title={item.title}
                    ></Chip>
                  );
                })}
              </div>
            </TitleWrap>
            {/* <TitleWrap title="키워드" subTitle="중복선택가능">
            <div className="flex items-center flex-wrap gap-2">
              {KEYWORD_LIST.map((item, i) => {
                return (
                  <Chip
                    selected={hashtagIds.includes(item.id)}
                    onClick={() => {
                      if (hashtagIds.includes(item.id)) {
                        setHashtagIds(hashtagIds.filter((i) => i !== item.id));
                      } else {
                        setHashtagIds((pre) => [...pre, item.id]);
                      }
                    }}
                    key={item.id}
                    title={item.title}
                  ></Chip>
                );
              })}
            </div>
          </TitleWrap> */}
            <TitleWrap title="특기" subTitle="중복선택가능">
              <div className="flex items-center flex-wrap gap-2">
                {HOBBY_LIST.map((item, i) => {
                  return (
                    <Chip
                      selected={hobbyIds.includes(item.id)}
                      onClick={() => {
                        if (hobbyIds.includes(item.id)) {
                          setHobbyIds(hobbyIds.filter((i) => i !== item.id));
                        } else {
                          setHobbyIds((pre) => [...pre, item.id]);
                        }
                      }}
                      key={item.id}
                      title={item.title}
                    ></Chip>
                  );
                })}
              </div>
            </TitleWrap>
            {/* <TitleWrap title="나이" subTitle="중복선택가능">
            <RangeInput fixedMinPrice={10} fixedMaxPrice={60} priceGap={10} />
          </TitleWrap>
          <TitleWrap title="몸무게" subTitle="중복선택가능">
            progrssBar
          </TitleWrap>
          <TitleWrap title="키" subTitle="중복선택가능">
            progrssBar
          </TitleWrap> */}
          </div>
          <div className="px-4">
            <Button
              onClick={() => {
                setArtistData({
                  ...artistData,
                  hashtagIds: hashtagIds.join(","),
                  hobbyIds: hobbyIds.join(","),
                  gender: gender,
                  job: job,
                });
                setIsSideTabOpen(false);
              }}
              className="w-full border-none bg-Blue04 text-white"
              text={"필터 적용하기"}
            ></Button>
          </div>
        </SideTab>
        <div className="flex items-center gap-4">
          <SearchBox
            onDropDownChange={onTypeChange}
            onChange={(e) => {
              const value = e.target.value;
              setSearchValues({
                ...searchValues,
                [selectedType]: value,
              });
            }}
            value={searchValues[selectedType]}
            placeholder="이름, 작품, 배역, 소속사"
          >
            {options.map((item) => {
              return (
                <option key={item.id} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </SearchBox>
        </div>
        <div>
          <div className="flex items-center my-4 justify-between">
            <select
              onChange={(e) => {
                setSort(e.target.value);
              }}
            >
              <option value="createdAt,desc">등록순</option>
              <option value="updatedAt,desc">최신순</option>
              <option value="birthDate,desc">나이순</option>
            </select>
            <Filter className="cursor-pointer" onClick={handleOpenTab} />
          </div>
        </div>
        {data?.pages[0].data.content.length === 0 ? (
          <div className="mt-36 items-center text-center">
            <div className="text-Gray05 RBody14">등록된 프로필이 없어요.</div>
          </div>
        ) : (
          <div className="relative">
            <ReactVirtuosoGrid
              isLoading={isLoading}
              useWindowScroll
              data={isLoading ? Array.from({ length: 1 }) : allLists}
              overscan={100}
              hasNextPage={hasNextPage}
              listClassName="grid grid-cols-3 gap-y-4 gap-x-2"
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={() => loadMore()}
              itemContent={(index, item) => {
                return (
                  <ArtistListCard
                    key={item?.profileId}
                    onClick={() => {
                      navigate(`${item?.profileId}`);
                    }}
                    title={item?.profileName}
                    src={item?.thumbnailUrl}
                    subTitle={`${splitBirth(item?.birthDate)}`}
                  />
                );
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ArtistList;
